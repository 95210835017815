import { Social, SocialTokens } from "../../../../services/contentNodes";
import axios from "axios";

export default {
	async socialInit(context, username) {
		try {
			if (!username) {
				alert("Social init has no username");
			}

			const cleanTokensObject = new SocialTokens();

			context.commit("setSocial", { prop: "tokens", val: cleanTokensObject });

			let hasFacebookTokensDB = false;
			let hasTwitterokensDB = false;
			let hasLinkedinTnokensDB = false;
			// let hasGoogleTokensDB = false;
			const methods = new Social();
			let updateDB = false;

			const dbResponseTokens = await axios.get(
				`/api/clients/getSocialTokens/${username}`
			);

			console.log("dbResponseTokenstest:", dbResponseTokens.data.google);

			context.commit("setSocial", {
				prop: "post/createdByUsername",
				val: username,
			});

			if (dbResponseTokens.data !== "") {
				if (dbResponseTokens.data.twitter.accessToken) {
					hasTwitterokensDB = true;
				}
				if (dbResponseTokens.data.linkedin.accessToken) {
					hasLinkedinTnokensDB = true;
				}
				if (
					dbResponseTokens.data.meta.pageAccessToken &&
					dbResponseTokens.data.meta.longliveAccessToken
				) {
					hasFacebookTokensDB = true;
				}
				// if(dbResponseTokens.data.google.accessToken && dbResponseTokens.data.google.refreshToken ){ hasGoogleTokensDB = true; }
				context.commit("setSocial", {
					prop: "tokens",
					val: dbResponseTokens.data,
				});
			}

			if (hasFacebookTokensDB === false) {
				const fbTokens = await axios.get(
					`/api/authorisation/facebookAccessTokens/${context.getters.getClientSlug}`
				);
				if (fbTokens && fbTokens.data !== "false") {
					const longliveAccessToken = fbTokens.data.FB_data.longliveAccessToken;
					const pageAccessToken = fbTokens.data.FB_data.pageAccessToken;
					//You must first get list of all facebook pages and then do api query to get the linked insta page that is connected to that fb page. its only way.
					const responseFacebookPages = await axios.get(
						`https://graph.facebook.com/me/accounts?access_token=${longliveAccessToken}`
					);
					context.commit("setSocial", {
						prop: "tokens/meta",
						val: {
							allFacebookPages: responseFacebookPages.data.data,
							longliveAccessToken: longliveAccessToken,
							pageAccessToken: pageAccessToken,
							instaPageID: "",
							facebookPageID: "",
							expire: methods.tokens.calculateMetaExpireDate(new Date()),
						},
					});
					updateDB = true;
				}
			}
			if (hasTwitterokensDB === false) {
				const twTokens = await axios.get(
					`/api/authorisation/TwitterAccessTokens/${context.getters.getClientSlug}`
				);
				if (twTokens && twTokens.data !== "false") {
					const accessToken = twTokens.data.TW_data.accessToken;
					const accessTokenSecret = twTokens.data.TW_data.accessTokenSecret;

					//We had to get the twitter account info here becuase the api is very tempitential and only allows certain amount of requests a day.
					// const twitterAccountInfo = await axios.get(`/api/twitterAccountInformation?token=${accessToken}`);

					// context.commit('setSocial', {prop: 'tokens/twitter', val: {username: twitterAccountInfo.username, pageName: twitterAccountInfo.pageName, accessToken: accessToken, accessTokenSecret: accessTokenSecret, expire: methods.tokens.calculateMetaExpireDate(new Date())}});

					context.commit("setSocial", {
						prop: "tokens/twitter",
						val: {
							accessToken: accessToken,
							accessTokenSecret: accessTokenSecret,
							expire: methods.tokens.calculateMetaExpireDate(new Date()),
						},
					});
					updateDB = true;
				}
			}

			if (hasLinkedinTnokensDB === false) {
				const twTokens = await axios.get(
					`/api/authorisation/linkedinAccessTokens/${context.getters.getClientSlug}`
				);
				if (twTokens && twTokens.data !== "false") {
					const accessToken = twTokens.data.LI_data.accessToken;
					const companyData = await axios.get(
						`/api/clients/getLinkedinCompanyIds/${accessToken}`
					);
					context.commit("setSocial", {
						prop: "tokens/linkedin",
						val: {
							accessToken: accessToken,
							companyID: "",
							userLinkedCompanyData: companyData.data,
							expire: methods.tokens.calculateMetaExpireDate(new Date()),
						},
					});
					updateDB = true;
				}
			}

			// if(hasGoogleTokensDB === false){
			// 	const twTokens = await axios.get(`/api/authorisation/googleKey/${context.getters.getClientSlug}`);
			// 	console.log('socialInit hasGoogleTokensDB === false....', twTokens);
			// 	if(twTokens && twTokens.data !== 'false'){
			// 		const accessToken = twTokens.data.access_token;
			// 		const profileId = twTokens.data.profileId;
			// 		const params = new URLSearchParams();
			// 		params.append('user', context.getters.getClientSlug);
			// 		params.append('profileId', profileId);
			// 		const refreshToken = twTokens.data.googleRefreshToken;
			// 		// const refreshToken = await axios.post('/api/clients/getCopyOfGoogleRefreshToken', params, {
			// 		// 	headers: {
			// 		// 		'Content-Type': 'application/x-www-form-urlencoded',
			// 		// 	},
			// 		// });

			// 		if(refreshToken && accessToken && profileId){
			// 			context.commit('setGoogleAccessToken', accessToken);
			// 			const cleanTokensObject = new SocialTokens();
			// 			let cleanGoogleObject = cleanTokensObject.google;
			// 			cleanGoogleObject.accessToken = accessToken;
			// 			cleanGoogleObject.refreshToken = refreshToken;
			// 			cleanGoogleObject.expire = methods.tokens.calculateMetaExpireDate(new Date());
			// 			cleanGoogleObject.profileId = profileId;
			// 			context.commit('setSocial', {prop: 'tokens/google', val: cleanGoogleObject});
			// 			updateDB = true
			// 		}else{
			// 			alert('Problem getting tokens. Review the console log');
			// 			console.log('Google tokens recieved:', {accessToken: accessToken, refreshToken: refreshToken, profileId: profileId})
			// 		}

			// 	}

			// }

			if (updateDB) {
				await context.dispatch("updateSocialTokens", username);
			}

			let post;

			const urlParams = new URLSearchParams(window.location.search);

			const node = urlParams.get("node");

			if (context.getters.getCurrentNode.preConnectedSocialPostID) {
				console.log(
					"social post has pre connected social post ID",
					context.getters.getCurrentNode.preConnectedSocialPostID
				);
				await context.commit("setSocial", { prop: "post/nodeID", val: node });
				post = await axios.get(
					`/api/clients/findSocialPost/${node}?socialPostID=${context.getters.getCurrentNode.preConnectedSocialPostID}`
				);
			} else {
				await context.commit("setSocial", { prop: "post/nodeID", val: node });
				post = await axios.get(`/api/clients/findSocialPost/${node}`);
			}

			if (post.data !== "") {
				await context.commit("setSocial", { prop: "post", val: post.data });
				if (
					context.getters.getSocial.currentPageSectionThatIsOpen &&
					post.data.error
				) {
					alert(
						"There was an error publishing this post. re-save the post to remove this error pop up."
					);
					console.log(
						"The error that was displayed when the post was being published:",
						post.data.error
					);
				}
			} else {
				const applyObj = {
					slug: context.getters.getClientSlug,
					username: username,
					nodeID: node,
				};
				await context.commit("resetSocialPost", applyObj);
			}

			const posts = await axios.get(
				`/api/clients/findSocialPosts/${context.getters.getClientSlug}`
			);

			if (posts.data.length > 0) {
				await context.commit("setSocial", { prop: "calendar", val: post.data });
			}
			if (
				context.getters.getSocial.tokens.linkedin.companyID === "" &&
				context.getters.getSocial.tokens.linkedin.userLinkedCompanyData.length >
					0
			) {
				context.commit("setSocial", {
					prop: "accountSelectIsOpen",
					val: "linkedin",
				});
			}
			if (
				context.getters.getSocial.tokens.meta.facebookPageID === "" &&
				context.getters.getSocial.tokens.meta.allFacebookPages.length > 0
			) {
				context.commit("setSocial", {
					prop: "accountSelectIsOpen",
					val: "meta",
				});
			}
			if (
				context.getters.getCurrentNode.fieldGroups.format.fields.focus.value ===
				"Facebook"
			) {
				context.commit("setSocial", {
					prop: `post/scheduleTo/facebook`,
					val: true,
				});
			}
			if (
				context.getters.getCurrentNode.fieldGroups.format.fields.focus.value ===
				"Instagram"
			) {
				context.commit("setSocial", {
					prop: `post/scheduleTo/instagram`,
					val: true,
				});
			}
			if (
				context.getters.getCurrentNode.fieldGroups.format.fields.focus.value ===
				"X"
			) {
				context.commit("setSocial", {
					prop: `post/scheduleTo/twitter`,
					val: true,
				});
			}
			if (
				context.getters.getCurrentNode.fieldGroups.format.fields.focus.value ===
				"Linkedin"
			) {
				context.commit("setSocial", {
					prop: `post/scheduleTo/linkedin`,
					val: true,
				});
			}

			await context.dispatch("checkAuthenticatedGoogleAccessToken");
			await context.dispatch("getSocialAccountStatus");
		} catch (error) {
			console.log("socialInit error", error);
		}
	},
};

import axios from "axios";
import { setGoogleData } from "../../../../../mixins/setGoogleDataFunc";
const dayMilliseconds = 86400000;

function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default {
	async ppcAllDataBlockInit(context) {
		try {
			console.log("ppcAllDataBlockInit");

			// API vars
			const metricClean = "googleAds";
			const metricsURL = `https://googleads.googleapis.com/v18/customers/${context.getters.getSocial.tokens.google.adsAccountID}/googleAds:searchStream?query=`;
			const metricQuery = `SELECT campaign.id,campaign.name, metrics.clicks, metrics.impressions, metrics.ctr, metrics.average_cpc, metrics.conversions, metrics.conversions_from_interactions_rate, metrics.cost_per_conversion, metrics.search_impression_share FROM campaign WHERE segments.date DURING LAST_30_DAYS AND campaign.status = 'ENABLED'`;
			let createNewMetric = false;
			let updatedGoogleData;
			// Work out the time since the last update
			let timeNow = Date.now();
			// If this is the first time it's been loaded there's nothing in the database so:
			// 1. set the time higher to ensure the API data is collected
			let timeUpdated = dayMilliseconds + 1;
			if (context.getters.getClientGoogleData.metrics[metricClean]) {
				// 2. If the data does exist replace the time updated & set the initial variables from the DB
				timeUpdated =
					context.getters.getClientGoogleData.metrics[metricClean].updated;
				context.commit("setComponents", {
					prop: "ppc/AllDataBlock/tableRows",
					val: context.getters.getClientGoogleData.metrics[metricClean].tables,
				});
			} else {
				// And if it doesn't add a placeholder for the new metric
				createNewMetric = true;
			}

			let timeSince = timeNow - timeUpdated;

			const response = await axios.get(
				`/api/clients/googleData/${context.getters.getClientSlug}`
			);

			updatedGoogleData = response.data;

			// If the data hasn't been updated for over a day update from the API
			if (timeSince > dayMilliseconds) {
				if (createNewMetric) {
					updatedGoogleData.metrics[metricClean] = {};
				}

				const res = await axios({
					method: "post",
					url: metricsURL + metricQuery,
					params: { HTTP_CONTENT_LANGUAGE: self.language }, // [TODO] work out if the query can go here
					headers: {
						Authorization: `Bearer ${context.getters.getSocial.tokens.google.accessToken}`,
						"developer-token": "I0zHnS4lyNgu-pUfz8r1Vw",
						"login-customer-id": "2714889262",
					},
				});

				const data = res.data;

				console.log("All Data", data[0]);
				let results = data[0].results;

				let allRows = [];

				for (let [key, item] of Object.entries(results)) {
					console.log(key);
					// Get the individual metrics/details
					let campaign = item.campaign.name;
					let clicks = item.metrics.clicks;
					let impressions = item.metrics.impressions;
					let ctr = parseFloat(item.metrics.ctr * 100).toFixed(2) + "%";
					let avg_cpc_val = parseFloat(
						item.metrics.averageCpc / 1000000
					).toFixed(2); // Not sure why this comes out at millions of pounds
					let avg_cpc = "£" + numberWithCommas(avg_cpc_val);
					let cost = "£" + numberWithCommas(item.metrics.clicks * avg_cpc_val);
					let conversions = item.metrics.conversions;
					let conv_rate =
						parseFloat(
							item.metrics.conversionsFromInteractionsRate * 100
						).toFixed(2) + "%";
					let conv_cost_val = parseFloat(
						item.metrics.costPerConversion / 1000000
					).toFixed(2); // Not sure why this comes out at millions of pounds
					let conv_cost = "£" + numberWithCommas(conv_cost_val);
					let search =
						parseFloat(item.metrics.searchImpressionShare * 100).toFixed(2) +
						"%";
					// Create an array for each table row with the full data
					let row = [
						campaign,
						clicks,
						impressions,
						ctr,
						avg_cpc,
						cost,
						conversions,
						conv_rate,
						conv_cost,
						search,
					];

					allRows.push(row);
				}

				context.commit("setComponents", {
					prop: "ppc/AllDataBlock/tableRows",
					val: allRows,
				});
				updatedGoogleData.metrics[metricClean].updated = Date.now();
				updatedGoogleData.metrics[metricClean].tables =
					context.getters.getComponents.ppc.AllDataBlock.tableRows;
				await setGoogleData(
					updatedGoogleData.metrics[metricClean],
					metricClean,
					context.getters.getClientSlug
				);
			}
		} catch (error) {
			console.log("All Data error", error);
		}
	},
};

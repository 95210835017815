import axios from "axios";

// import {conversionRate} from '../../../../../mixins/conversionRateFunc';
import { setGoogleData } from "../../../../../mixins/setGoogleDataFunc";
const dayMilliseconds = 86400000;

export default {
	async SEOlatestRankingBlockInit(context) {
		try {
			let updatedGoogleData;

			// API urls
			const metricClean = "googleLatestRanking"; // Note: sourced from agency analytics
			//  const agencyAnalyticsURL = `https://api.clientseoreport.com/v3/resources/rankings/campaign/date`;

			/*
			 * connect and grab the daily visitors for the last month, to create the graph.
			 */
			const response = await axios.get(
				`/api/clients/googleData/${context.getters.getClientSlug}`
			);

			updatedGoogleData = response.data;

			// let dailyHits = []
			// let dailyLabels = []
			let timeNow = Date.now();
			// If this is the first time it's been loaded there's nothing in the database so:
			// 1. set the time higher to ensure the API data is collected
			let timeUpdated = dayMilliseconds + 1;

			if (response.data.metrics[metricClean]) {
				// 2. If the data does exist replace the time updated
				timeUpdated = response.data.metrics[metricClean].updated;
			} else {
				// And if it doesn't add a placeholder for the new metric
				updatedGoogleData.metrics[metricClean] = {};
			}
			let timeSince = timeNow - timeUpdated;

			if (timeSince > dayMilliseconds) {
				/*
				 * The data is over a day old, so update from the API and save the updated information to the database.
				 */
				console.log("updated ranking over a day ago");
				var date = new Date();
				date.setDate(date.getDate());
				date = date.toISOString().slice(0, 10);

				const base64ApiKey = Buffer.from(
					context.getters.getSocial.tokens.google.agencyAPIkey
				);
				const res = await axios({
					method: "post",
					url: "https://apirequest.app/query",
					headers: {
						"Content-Type": "application/json",
						Authorization: base64ApiKey,
					},
					data: {
						provider: "agency-analytics-v2",
						asset: "campaign-rankings",
						operation: "read",
						fields: ["date", "google_ranking_change", "ranking_average"],
						filters: [
							{ end_date: { $lessthanorequal_comparison: date } },
							{ start_date: { $greaterthanorequal_comparison: date } },
							{
								campaign_id: {
									$equals_comparison:
										context.getters.getSocial.tokens.google.agencyCampaign,
								},
							},
						],
						group_by: ["date"],
						sort: [{ date: "asc" }],
						offset: 0,
						limit: 9999,
					},
				});

				let results = res.data.results.rows;
				context.commit("setComponents", {
					prop: "seo/LatestRankingBlock/ranking",
					val: results[0].ranking_average,
				});
				updatedGoogleData.metrics[metricClean].current =
					context.getters.getComponents.seo.LatestRankingBlock.ranking;
				updatedGoogleData.metrics[metricClean].updated = Date.now();
				await setGoogleData(
					updatedGoogleData.metrics[metricClean],
					metricClean,
					context.getters.getClientSlug
				);
			} else {
				context.commit("setComponents", {
					prop: "seo/LatestRankingBlock/ranking",
					val: updatedGoogleData.metrics[metricClean].current,
				});
			}
		} catch (error) {
			console.log("analytics error init", error);
		}
	},
};

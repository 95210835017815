import axios from "axios";
import { conversionRate } from "../../../../../mixins/conversionRateFunc";
import { setGoogleData } from "../../../../../mixins/setGoogleDataFunc";
const dayMilliseconds = 86400000;

export default {
	async ppcConversionRateBlockInit(context) {
		try {
			console.log("ppcConversionRateBlockInit");

			// Day vars, the ads API does support the XXdaysago option
			let dateYesterday = new Date();
			let dateMinus28 = new Date();
			let dateMinus56 = new Date();
			dateYesterday.setDate(dateYesterday.getDate() - 1);
			dateYesterday = dateYesterday.toISOString().slice(0, 10);
			dateMinus28.setDate(dateMinus28.getDate() - 28);
			dateMinus28 = dateMinus28.toISOString().slice(0, 10);
			dateMinus56.setDate(dateMinus56.getDate() - 56);
			dateMinus56 = dateMinus56.toISOString().slice(0, 10);

			// API vars
			const metricClean = "googleAdsConversionRate";
			// Note: as we're getting the combined conversionRate for all things we need all the clicks and conversions so the percentage can be got from there.
			const metricsURL = `https://googleads.googleapis.com/v18/customers/${context.getters.getSocial.tokens.google.adsAccountID}/googleAds:searchStream?query=SELECT metrics.clicks, metrics.conversions FROM campaign WHERE segments.date BETWEEN `;
			const metricQueryThisMonth = `'${dateMinus28}' AND '${dateYesterday}' AND campaign.status = 'ENABLED'`;
			const metricQueryLastMonth = `'${dateMinus56}' AND '${dateMinus28}' AND campaign.status = 'ENABLED'`;
			let createNewMetric = false;
			let updatedGoogleData;

			// Work out the time since the last update
			let timeNow = Date.now();
			// If this is the first time it's been loaded there's nothing in the database so:
			// 1. set the time higher to ensure the API data is collected
			let timeUpdated = dayMilliseconds + 1;

			if (context.getters.getClientGoogleData.metrics) {
				if (context.getters.getClientGoogleData.metrics[metricClean]) {
					// 2. If the data does exist replace the time updated & set the initial variables from the DB
					timeUpdated =
						context.getters.getClientGoogleData.metrics[metricClean].updated;
					context.commit("setComponents", {
						prop: "ppc/ConversionRateBlock/conversionRateVal",
						val: context.getters.getClientGoogleData.metrics[metricClean]
							.current,
					});
					context.commit("setComponents", {
						prop: "ppc/ConversionRateBlock/conversionRateComparison",
						val: context.getters.getClientGoogleData.metrics[metricClean]
							.previous,
					});
					// Set the class based on the rate
					context.commit("setComponents", {
						prop: "ppc/ConversionRateBlock/conversionRateClass",
						val: conversionRate(
							context.getters.getComponents.ppc.ConversionRateBlock
								.conversionRateComparison
						),
					});
				} else {
					// And if it doesn't add a placeholder for the new metric
					createNewMetric = true;
				}
			} else {
				createNewMetric = true;
			}

			let timeSince = timeNow - timeUpdated;

			const response = await axios.get(
				`/api/clients/googleData/${context.getters.getClientSlug}`
			);

			updatedGoogleData = response.data;

			// If the data hasn't been updated for over a day update from the API
			if (timeSince > dayMilliseconds) {
				if (createNewMetric) {
					updatedGoogleData.metrics[metricClean] = {};
				}

				const res = await axios({
					method: "post",
					url: metricsURL + metricQueryThisMonth,
					params: { HTTP_CONTENT_LANGUAGE: self.language }, // [TODO] work out if the query can go here
					headers: {
						Authorization: `Bearer ${context.getters.getSocial.tokens.google.accessToken}`,
						"developer-token": "I0zHnS4lyNgu-pUfz8r1Vw",
						"login-customer-id": "2714889262",
					},
				});

				const data = res.data;

				let results = data[0].results;
				let thisMonthClicks = 0;
				let thisMonthConversions = 0;
				for (let [key, item] of Object.entries(results)) {
					console.log(key);
					let clicks = item.metrics.clicks;
					thisMonthClicks = thisMonthClicks + parseFloat(clicks);
					let conversions = item.metrics.conversions;
					thisMonthConversions = thisMonthConversions + parseFloat(conversions);
				}
				context.commit("setComponents", {
					prop: "ppc/ConversionRateBlock/conversionRateVal",
					val: ((thisMonthConversions / thisMonthClicks) * 100).toFixed(2),
				});
				updatedGoogleData.metrics[metricClean].current =
					context.getters.getComponents.ppc.ConversionRateBlock.conversionRateVal;
				// The get the previous months for comparison
				const res2 = await axios({
					method: "post",
					url: metricsURL + metricQueryLastMonth,
					params: { HTTP_CONTENT_LANGUAGE: self.language }, // [TODO] work out if the query can go here
					headers: {
						Authorization:
							"Bearer " + context.getters.getSocial.tokens.google.accessToken,
						"developer-token": "I0zHnS4lyNgu-pUfz8r1Vw",
						"login-customer-id": "2714889262",
					},
				});

				const data2 = res2.data;

				// console.log(data[0])
				let result2 = data2[0].results;
				let thisMonthClicks2 = 0;
				let thisMonthConversions2 = 0;
				for (let [key, item] of Object.entries(result2)) {
					console.log(key);
					let clicks = item.metrics.clicks;
					thisMonthClicks2 = thisMonthClicks2 + parseFloat(clicks);
					let conversions = item.metrics.conversions;
					thisMonthConversions2 =
						thisMonthConversions2 + parseFloat(conversions);
				}
				context.commit("setComponents", {
					prop: "ppc/ConversionRateBlock/conversionRateComparison",
					val: (
						(parseFloat(
							context.getters.getComponents.ppc.ConversionRateBlock
								.conversionRateVal
						) /
							((thisMonthConversions2 / thisMonthClicks2) * 100).toFixed(2)) *
							100 -
						100
					).toFixed(2),
				});
				context.commit("setComponents", {
					prop: "ppc/ConversionRateBlock/conversionRateClass",
					val: conversionRate(
						context.getters.getComponents.ppc.ConversionRateBlock
							.conversionRateComparison
					),
				});
				updatedGoogleData.metrics[metricClean].previous =
					context.getters.getComponents.ppc.ConversionRateBlock.conversionRateComparison;
				updatedGoogleData.metrics[metricClean].updated = Date.now();
				// Push the updated data to the DB
				await setGoogleData(
					updatedGoogleData.metrics[metricClean],
					metricClean,
					context.getters.getClientSlug
				);
			}
		} catch (error) {
			console.log("error", error);
		}
	},
};

import axios from "axios";
import router from "@/router";
import store from "../../../store";
import {
	exampleContentTree,
	NodeBlogContent,
	Mention,
	SocialTokens,
	SocialAccountInformation,
} from "../../../services/contentNodes";
import socialInit from "./actions/socialInit";
import downloadBlog from "./actions/downloadBlog";
import checkAuthenticatedGoogleAccessToken from "./actions/checkAuthenticatedGoogleAccessToken";
import notifyMainContactForApproval from "./actions/notifyMainContactForApproval";
import nodeApproval from "./actions/nodeApproval";
import AIGenerateText from "./actions/AIGenerateText";
import approveAIGeneratedContent from "./actions/AIGenerateText";

export default {
	/**
	 * Grabs all client data from the database and populates it into the vuex store for use within the app.
	 *
	 * @param {Object} context Vuex default context object
	 * @param {String} clientSlug Client slug taken from the URL param
	 * @returns the response so we can use 'await' on this action call elsewhere
	 */

	...socialInit,
	...downloadBlog,
	...checkAuthenticatedGoogleAccessToken,
	...notifyMainContactForApproval,
	...nodeApproval,
	...AIGenerateText,
	...approveAIGeneratedContent,

	async fetchClientData(context, clientSlug) {
		try {
			const response = await axios.get(`/api/clients/findClient/${clientSlug}`);
			await context.commit("setClientData", response.data);
			return response.data;
		} catch (err) {
			console.log(err.response);
		}
	},
	async fetchClientContentTree(context, clientSlug) {
		try {
			console.log("fetchClientContentTree");
			const response = await axios.get(
				`/api/clients/contentTree/${clientSlug}`
			);
			console.log("FETCH CLIENT CONTENT TREE", response.data);
			await context.commit("setClientContentTree", response.data);
			return response.data;
		} catch (err) {
			console.log(err.response);
		}
	},
	async getClientContentTreeReach(context, clientSlug) {
		try {
			const response = await axios.get(
				`/api/clients/getClientContentTreeReach/${clientSlug}`
			);
			await context.commit("setClientContentTreeReach", Number(response.data));
			return response.data;
		} catch (err) {
			console.log(err.response);
		}
	},
	clearClientData(context) {
		context.commit("setClientData", {});
		context.dispatch("clearHighlightNode");
	},

	async createClient(context, image) {
		let tree = exampleContentTree;
		tree.nodes[0].fieldGroups.status.fields.status.value = "Category";
		let contentTree = JSON.stringify(tree);
		let clientData = JSON.stringify(context.getters.getClientData);

		const formData = new FormData();
		formData.append("clientData", clientData);
		formData.append("contentTree", contentTree);

		if (image) {
			formData.append("avatar", image);
		}

		return axios
			.post("/api/clients/createClient", formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then(async (response) => {
				const cleanTokensObject = new SocialTokens();
				context.commit("setSocial", { prop: "tokens", val: cleanTokensObject });
				await context.dispatch("updateSocialTokens", response.data.client.slug);
				console.log("createClient");
				await context.dispatch("fetchClientData", response.data.client.slug);
				return response.data;
			})
			.catch(function (err) {
				return err.response.data?.message;
			});
	},

	async updateClient(context, image) {
		let clientData = JSON.stringify(context.getters.getClientData);

		const formData = new FormData();
		formData.append("clientData", clientData);
		if (image) {
			// console.log('appending image');
			formData.append("avatar", image);
		}

		return axios
			.post(
				`/api/clients/updateClient/${context.getters.getClientSlug}`,
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			)
			.then((response) => {
				// Need to set the new client data here
				console.log("updateClient");
				context.commit("setClientData", response.data.client);
				return response.data;
			})
			.catch(function (err) {
				return err.response.data;
			});
	},

	async updateClientDeletedAt(context) {
		const params = new URLSearchParams();
		const header = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		};

		params.append("clientSlug", context.getters.getClientSlug);
		params.append("data", JSON.stringify(context.getters.getClientData));

		axios
			.post(
				`/api/clients/updateClientDeletedAt/${context.getters.getClientSlug}`,
				params,
				header
			)
			.then(() => {
				console.log(" request completed successfully");
			})
			.catch((err) => {
				console.error("At least one of the requests failed:", err);
			});
	},

	/**
	 * This function can be used to set the 'current node' when a node is selected in the vuex store
	 * Calls the setNode mutation
	 * @param {Object} context Vuex default context object
	 * @param {String} nodeId The ID of the selected NodeLink
	 */
	async selectNode(context, nodeId) {
		try {
			const [selectedNodeData, response, commentResponse] = await Promise.all([
				axios.get(`/api/clients/getCurrentNode/${nodeId}`),
				axios.get(`/api/clients/findNodeBlogContent/${nodeId}`),
				axios.get(`/api/clients/getComments/${nodeId}`),
			]);
			console.log("SELECTED NODE BLOG CONTENT STEP111111:", response.data);
			if (response.data) {
				context.commit("setBlogContentIsInDB", true);
				context.commit("setNodeBlogContent", response.data);
			} else {
				context.commit("setBlogContentIsInDB", false);
				context.commit("setNodeBlogContentID", nodeId);
			}
			context.commit(
				"setClientNodeComments",
				commentResponse.data ? commentResponse.data : []
			);

			// Setting node in vuex
			await context.commit("setNode", selectedNodeData.data);

			// Updating the route query to match the selected node
			console.log("current query:", router.currentRoute.query);
			const url = new URL(window.location.href);
			let currentQuery = {
				list: url.searchParams.get("list"),
				format: url.searchParams.get("format"),
				"editor-open": url.searchParams.get("editor-open"),
				"load-editor": url.searchParams.get("load-editor"),
				node: nodeId,
			};
			await router.push({ query: currentQuery });
			console.log("current query new:", currentQuery);
		} catch (error) {
			context.commit("setNodeBlogContentID", nodeId);
			context.commit("setClientNodeComments", []);
		}
	},
	async isNodeLocked(context, nodeId) {
		try {
			const params = new URLSearchParams();
			params.append("authUser", context.getters.getAuthUsername);
			params.append("nodeId", nodeId);
			const response = await axios.post(`/api/clients/isNodeLocked`, params, {
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
			});
			const isNodeLocked = response.data;
			if (isNodeLocked) {
				alert(
					"Sorry, someone is editing this a node inside this category/branch"
				);
			}
			return isNodeLocked;
		} catch (error) {
			console.log("isNodeLocked catch error", error);
		}
	},
	async updateNodeActionRecord(context, data) {
		const params = new URLSearchParams();
		params.append("authUser", context.getters.getAuthUsername);
		params.append("client", context.getters.getClientSlug);
		params.append("action", data.action);
		params.append("nodeId", data.nodeId);
		try {
			await axios.post(`/api/clients/updateNodeActionRecord`, params, {
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
			});
		} catch (error) {
			console.log("updateNodeActionRecord catch error", error);
		}
	},
	/**
	 * Clears the current selected Node
	 * @param {Object} context Vuex default context object
	 * @param {String} nodeId The ID of the selected NodeLink
	 */
	async clearSelectedNode(context) {
		await context.commit("clearSelectedNode");

		// Create a new route object
		let route = {
			origin: this.userOrigin,
			destination: this.userDestination,
			query: { ...store.getters.getRoute.query },
		};

		// Remove the node property from the query object
		delete route.query.node;

		// Commit the mutation
		context.commit("updateRoute", route);

		// Replace the current route with the new route
		router.replace(route);

		return;
	},

	/**
	 * This function can be used to set the 'hover highlight' when a node is hovered in the vuex store
	 * Calls the highlightNode mutation
	 * @param {Object} context Vuex default context object
	 * @param {String} nodeId The ID of the selected NodeLink
	 */
	async highlightNode(context, nodeId) {
		await context.commit("highlightNode", nodeId);
		return;
	},

	/**
	 * This function can be used to remove the 'hover highlight' when a node is hovered off in the vuex store
	 * Calls the clearHighlightNode mutation
	 * @param {Object} context Vuex default context object
	 * @param {String} nodeId The ID of the selected NodeLink
	 */
	async clearHighlightNode(context) {
		await context.commit("clearHighlightNode");
		return;
	},
	async updateMentions(context, commentOBJ) {
		try {
			if (typeof commentOBJ === "string") {
				//commentOBJ is an ID to find and delete in the mentions array.
				let currentMentionsArr = context.getters.getMentions;
				if (currentMentionsArr) {
					let updatedMentionsArr = currentMentionsArr.filter(
						(mention) => mention.id !== commentOBJ
					);
					await context.commit("setMentions", updatedMentionsArr);
					return;
				}
			} else {
				//ADD, UPDATE, REPLY
				const response = await axios.get("/api/user/getAllUsers");
				const usersArray = response.data;
				let words = commentOBJ.comment.split(" ");
				let atWords = [];
				let mentionsArr = [];
				for (let word of words) {
					if (word.startsWith("@")) {
						atWords.push(word);
					}
				}
				for (let atWord of atWords) {
					const mentionedUserObj = usersArray.find(
						(user) => user.username === atWord.slice(1)
					);
					let mention = new Mention();
					(mention.commentID = commentOBJ.id),
						(mention.comment = commentOBJ.comment),
						(mention.commentCreator = commentOBJ.user),
						(mention.username = mentionedUserObj.username),
						(mention.email = mentionedUserObj.email);
					mentionsArr.push(mention);
				}
				let currentMentionsArr = context.getters.getMentions;
				let newMentionsArr = [...currentMentionsArr, ...mentionsArr];
				context.commit("setMentions", newMentionsArr);
				return;
			}
		} catch (error) {
			console.log("updateMentions error:", error);
		}
	},
	async updateComments(context) {
		//We must update the blog content first as a comment may link to some of the wording.

		let promises = [];
		let currentNodeBlogContent = context.getters.getCurrentNodeBlogContent;
		let featuredImage =
			context.getters.getCurrentNodeBlogContent.featuredImageURL;

		const nodeBlogContentOBJ = {
			id: currentNodeBlogContent.id,
			nodeID: currentNodeBlogContent.nodeID,
			featuredImageURL: currentNodeBlogContent.featuredImageURL,
			blogHTML: currentNodeBlogContent.blogHTML,
			reviewed: currentNodeBlogContent.reviewed,
			title: currentNodeBlogContent.title,
		};

		let updatedNodeBlogContent = JSON.stringify(nodeBlogContentOBJ);

		let commentArr = context.getters.getClientNodeComments;

		const mentions = context.getters.getMentions;

		const params = new URLSearchParams();
		params.append("comments", JSON.stringify(commentArr));
		params.append("mentions", JSON.stringify(mentions));
		params.append("clientSlug", context.getters.getClientSlug);
		params.append("updatedNodeBlogContent", updatedNodeBlogContent);
		params.append("featuredImage", featuredImage);
		const ID = context.getters.getContentStrategy.isShowing
			? context.getters.getContentStrategy.currentNode
			: context.getters.getCurrentNode;
		params.append("nodeID", ID);
		console.log("update comment:", context.getters.getContentStrategy);
		promises.push(
			axios.post("/api/clients/contentTree/updateComments", params, {
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
		);

		promises.push(
			axios.post("/api/clients/contentTree/updateNodeBlogContent", params, {
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
		);

		await Promise.all(promises)
			.then(async () => {
				context.commit("setHasCommentsBeenUpdated", true);
				context.commit("setMentions", []);
				return;
			})
			.catch(function (err) {
				console.log("FAILURE!!", err);
			});
	},
	/**
	 *
	 * @param {Object} context vuex context object
	 * @returns the response so we can use 'await' on this action call elsewhere
	 */
	async saveClientContentTree(context, nodeDeleted = false) {
		// Allow the tree to perform methods required before save
		// Such as accumulating the reach values

		try {
			let updatedNode = JSON.stringify(context.getters.getCurrentNode);

			const params = new URLSearchParams();

			params.append("clientSlug", context.getters.getClientSlug);

			params.append("authUsername", context.getters.getAuthUsername);

			params.append("updatedNode", updatedNode);

			params.append("deleteNode", nodeDeleted);

			const response = await axios.post(
				"/api/clients/contentTree/updateContentTree",
				params,
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			);
			console.log("saveClientContentTree");
			await context.dispatch("fetchClientData", context.getters.getClientSlug);

			await context.dispatch("saveBlog");

			// So we just refetched new data, meaning the current node no longer exists
			// Even though it still exists in vuex. We need to clear that node out of Vuex
			// And replace it with the same node from the new fetched data.

			// Save the Id of the node before we clear it
			const oldNodeId = context.getters.getCurrentNode.id;
			// Clear it
			context.commit("clearSelectedNode");

			// Reselect it from the new data
			if (!nodeDeleted) {
				if (response.data.newNodeId) {
					await context.dispatch("selectNode", response.data.newNodeId);
				} else {
					await context.dispatch("selectNode", oldNodeId);
				}
			}

			context.commit("setIsCreatingNewNodeToAddToDB", false);
			return response;
		} catch (error) {
			console.log("=== saveClientContentTree catch error ===", error);
		}
	},
	async saveClientContentList(context, nodeDeleted) {
		// This is for when the user moves a node. saving ops such as the data of the node are saveClientContentTree
		console.log(nodeDeleted);

		try {
			let updatedTree = JSON.stringify(context.getters.getClientContentTree);
			const params = new URLSearchParams();
			params.append("clientSlug", context.getters.getClientSlug);
			params.append("updatedTree", updatedTree);
			console.log("saveClientContentList new info:", [
				context.getters.getClientContentTree,
				context.getters.getImportedContentTree,
			]);
			const response = await axios.post(
				"/api/clients/contentTree/updateContentList",
				params,
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			);

			console.log("========= approving-nodes =======", [
				context.getters.getContentStrategy,
			]);
			console.log("========= approving-nodes =======", [
				context.getters.getContentStrategy.approvedNodes,
			]);
			if (context.getters.getContentStrategy.approvedNodes.length > 0) {
				await context.dispatch("approveContentStrategyNodes");
			}
			console.log("saveClientContentTree");
			await context.dispatch("fetchClientData", context.getters.getClientSlug);
			// So we just refetched new data, meaning the current node no longer exists
			// Even though it still exists in vuex. We need to clear that node out of Vuex
			// And replace it with the same node from the new fetched data.

			// Save the Id of the node before we clear it
			const oldNodeId = context.getters.getCurrentNode.id;
			// Clear it

			await context.dispatch("clearSelectedNode");

			// Reselect it from the new data
			if (!nodeDeleted) {
				await context.dispatch("selectNode", oldNodeId);
			}

			context.commit("setIsCreatingNewNodeToAddToDB", false);

			return response;
		} catch (error) {
			console.log("=== saveClientContentTree catch error ===", error);
		}
	},

	// Working on this
	async addNewNode({ commit, state }, { parentNodeId, author }) {
		// console.log(state.clientContentTree);
		//console.log(author, "test in actions")
		commit("addChildNode", { parentNodeId, author });

		let treeNodes = state.clientContentTree.nodes;

		let newNodeId = treeNodes[treeNodes.length - 1].id;

		let nodeBlogContent = new NodeBlogContent();

		nodeBlogContent.nodeID = newNodeId;

		commit("setNodeBlogContent", nodeBlogContent);
		commit("setClientNodeComments", []);
		//commit('setNode', newNodeId);
	},

	// Working on this
	async removeNode({ commit, getters, dispatch }) {
		try {
			console.log("removeNode");
			await dispatch("removeNodeContent", getters.getCurrentNode.id);
			await dispatch("saveClientContentTree", true);
			commit("clearNode");
			await dispatch("fetchClientData", getters.getClientSlug);
			//	dispatch("client/clearSelectedNode")
			document.getElementById("main").classList.remove("overview-open");
			document.getElementById("main").classList.add("overview-closed");
			document
				.getElementById("node-overview")
				.classList.remove("overview-open");
			document.getElementById("node-overview").classList.add("overview-closed");
			this.$store.commit("client/setHidePageLoader", true);
		} catch (error) {
			console.log("removeNode catch error", error);
		}
		return;
	},
	async removeNodeContent(commit, nodeDeleted) {
		try {
			const params = new URLSearchParams();
			params.append("nodeId", nodeDeleted);

			await axios.post("/api/clients/contentTree/removeNodeContent", params, {
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
			});

			let nodeBlogContent = new NodeBlogContent();
			commit("setNodeBlogContent", nodeBlogContent);
			commit("setClientNodeComments", []);
		} catch (err) {
			console.log(err);
		}
	},
	/**
	 *
	 * @param {Object} filters the filters to be set
	 */
	updateContentTreeFilters({ commit }, filters) {
		commit("setFilters", filters);
	},

	clearContentTreeFilters({ commit }) {
		commit("setFilters", {});
	},
	async keywordPlanner(context, keyword) {
		console.log("actual keyword on keywordplanner:", keyword);
		try {
			alert(
				`This will search for ${keyword}. Please allow a moment to generate the table`
			);
			const googleResponse = await axios.get(
				`/api/google/keywordPlanner?keyword=${keyword}`
			);
			if (googleResponse.data.status === "Error") {
				alert(
					`Connection Error: ${googleResponse.data.message ? googleResponse.data.message : ""}`
				);
			}
			if (googleResponse.data && googleResponse.data.data) {
				context.commit("setKeywordPlanner", {
					prop: "tableData",
					val: googleResponse.data.data,
				});
			} else {
				console.log("No data received from Google Keyword Planner");
			}
		} catch (error) {
			console.log("keywordPlanner action error response:", error.response.data);
		}
	},
	async fetchMediaList(context) {
		try {
			const response = await axios.get(
				`/api/clients/fetchMediaList/${context.getters.getClientSlug}`
			);

			context.commit("setMediaList", response.data);
		} catch (err) {
			console.log("fetchMediaList action error response:", err);

			context.commit("setMediaList", []);
		}
	},
	async updateSocialTokens(context, slug = false) {
		try {
			const params = new URLSearchParams();
			const tokens = JSON.parse(
				JSON.stringify(context.getters.getSocial.tokens)
			);

			context.commit("setSocial", {
				prop: "tokens/google/agencyAPIkey",
				val: "Basic OjYyYzkzNTg0NGJhZTFkNWM2NTI5NmI3NzNiNzQ5ODMy",
			});

			console.log("updateSocialTokens", tokens);
			params.append("tokens", JSON.stringify(tokens));

			if (slug) {
				params.append("username", slug);
			} else {
				params.append("username", context.getters.getClientSlug);
			}

			const response = await axios.post(
				"/api/clients/updateSocialTokens/",
				params,
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			);

			// context.commit('setBlogModalOpen', false);
			console.log("api/clients/updateSocialTokens", response);
			await context.dispatch("getSocialAccountStatus");
			return response;
		} catch (error) {
			console.log("=== updateSocialTokens catch error ===", error);
		}
	},
	async getSocialTokens(context, username) {
		try {
			console.log("===== getSocialTokens starting ====");
			const dbResponseTokens = await axios.get(
				`/api/clients/getSocialTokens/${username}`
			);
			console.log("===== getSocialTokens data ====", dbResponseTokens);
			if (dbResponseTokens.data !== "") {
				context.commit("setSocial", {
					prop: "tokens",
					val: dbResponseTokens.data,
				});
			}
		} catch (error) {
			console.log("getSocialTokens catch error:", error);
		}
	},
	async updateSocialPost(context) {
		const defaultVal =
			context.getters.getCurrentNode.fieldGroups.format.fields.focus.value;
		if (defaultVal === "Facebook") {
			context.commit("setSocial", {
				prop: "post/scheduleTo/facebook",
				val: true,
			});
		}
		if (defaultVal === "Instagram") {
			context.commit("setSocial", {
				prop: "post/scheduleTo/instagram",
				val: true,
			});
		}
		if (defaultVal === "Twitter") {
			context.commit("setSocial", {
				prop: "post/scheduleTo/twitter",
				val: true,
			});
		}
		if (defaultVal === "Linkedin") {
			context.commit("setSocial", {
				prop: "post/scheduleTo/linkedin",
				val: true,
			});
		}

		const post = context.getters.getSocial.post;
		const tokens = context.getters.getSocial.tokens;
		const params = new URLSearchParams();
		params.append("post", JSON.stringify(post));
		params.append("tokens", JSON.stringify(tokens));
		try {
			const response = await axios.post(
				"/api/clients/updateSocialPost",
				params,
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			);
			console.log("newly created post from db with new id:", response.data.id);
			console.log("id been changed from:", context.getters.getSocial.post.id);
			context.commit("setSocial", { prop: "post/id", val: response.data.id });
			console.log("id been changed to:", context.getters.getSocial.post.id);
			alert("Post Updated Successfully");
		} catch (error) {
			alert("Request to update Post Failed");
		}
	},
	async getSocialPosts(context) {
		const params = new URLSearchParams();
		params.append("slug", context.getters.getClientSlug);
		try {
			const response = await axios.get(
				`/api/clients/getSocialPosts/${context.getters.getClientSlug}`
			);
			if (response.statusText !== "OK") {
				throw "Get Social Posts error";
			}
			context.commit("setSocial", { prop: "calendar", val: response.data });
		} catch (error) {
			console.log("getSocialPosts error:", error);
		}
	},
	async getBlogPosts(context) {
		const params = new URLSearchParams();
		params.append("slug", context.getters.getClientSlug);
		try {
			const response = await axios.get(
				`/api/clients/getBlogPosts/${context.getters.getClientSlug}`
			);
			if (response.statusText !== "OK") {
				throw "Get Blog Posts error";
			}
			console.log("getBlogPosts and now setting data", response.data);
			context.commit("setSocial", { prop: "calendar", val: response.data });
		} catch (error) {
			console.log("getBlogPosts error:", error);
		}
	},
	async saveBlog(context) {
		try {
			const isNodePosting = await context.dispatch("isNodePosting");
			console.log("saveBlog isNodePosting", [isNodePosting]);
			if (
				context.getters.getCurrentNode.fieldGroups.format.fields.format
					.value === "Blog"
			) {
				if (isNodePosting.status === false) {
					let updatedNode = JSON.stringify(context.getters.getCurrentNode);
					let currentNodeBlogContent =
						context.getters.getCurrentNodeBlogContent;
					const parser = new DOMParser();
					let blogHTML = parser.parseFromString(
						currentNodeBlogContent.blogHTML,
						"text/html"
					);

					console.log("currentNodeBlogContent", currentNodeBlogContent);

					const nodeBlogContentOBJ = {
						id: currentNodeBlogContent.id,
						nodeID: currentNodeBlogContent.nodeID,
						featuredImageURL: currentNodeBlogContent.featuredImageURL,
						blogHTML: blogHTML.documentElement.innerHTML,
						reviewed: currentNodeBlogContent.reviewed,
						title: currentNodeBlogContent.title,
						date: currentNodeBlogContent.date,
					};

					if (nodeBlogContentOBJ.title && nodeBlogContentOBJ.blogHTML) {
						let updatedNodeBlogContent = JSON.stringify(nodeBlogContentOBJ);

						const params = new URLSearchParams();
						params.append("clientSlug", context.getters.getClientSlug);
						params.append("updatedNode", updatedNode);
						params.append("updatedNodeBlogContent", updatedNodeBlogContent);

						await axios.post(
							"/api/clients/contentTree/updateNodeBlogContent",
							params,
							{
								headers: {
									"Content-Type": "application/x-www-form-urlencoded",
								},
							}
						);

						alert("Blog has been saved");
					} else {
						if (context.getters.getUserOpenedBlogOrSocialEditor) {
							alert("The blog requires a title and content to be saved");
						}
					}
				} else {
					alert("Node is currently posting to platform - Reload Shortly");
				}
			}
		} catch (error) {
			console.log("openAddNode catch error", error);
		}
	},
	async checkIfScheduledPostExsists(context) {
		try {
			const response = await axios.get(
				`/api/clients/getScheduledBlogPost/${context.getters.getCurrentNodeBlogContent.nodeID}`
			);
			console.log("checkIfScheduledPostExsists", response);
			if (response.data.hasbeenPublished === false) {
				const date = new Date(response.data.date);
				let day = date.getDate();
				let month = date.getMonth() + 1; // Months are zero based
				let year = date.getFullYear();
				let hours = date.getHours();
				let minutes = date.getMinutes();
				let seconds = date.getSeconds();
				// Pad single digit day, month, hours, minutes and seconds with a leading zero
				day = day < 10 ? "0" + day : day;
				month = month < 10 ? "0" + month : month;
				hours = hours < 10 ? "0" + hours : hours;
				minutes = minutes < 10 ? "0" + minutes : minutes;
				seconds = seconds < 10 ? "0" + seconds : seconds;
				const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
				alert(`Blog will be published on: ${formattedDate}`);
				context.commit("setScheduledBlogPost", {
					prop: "date",
					val: response.data.date,
				});
				context.commit("setScheduledBlogPost", {
					prop: "hasbeenPublished",
					val: response.data.hasbeenPublished,
				});
			}
			if (response.data.hasbeenPublished === true) {
				context.commit("setScheduledBlogPost", {
					prop: "hasbeenPublished",
					val: response.data.hasbeenPublished,
				});
			}
		} catch (error) {
			console.log("checkIfScheduledPostExsists error catch", error);
		}
	},
	async scheduleBlogPost(context) {
		// //check if not throw error
		try {
			context.commit("setScheduledBlogPost", {
				prop: "blogID",
				val: context.getters.getCurrentNodeBlogContent.nodeID,
			});
			let updatedNode = JSON.stringify(context.getters.getCurrentNode);
			const parser = new DOMParser();
			let blogHTML = parser.parseFromString(
				context.getters.getCurrentNodeBlogContent.blogHTML,
				"text/html"
			);
			const nodeBlogContentOBJ = {
				id: context.getters.getCurrentNodeBlogContent.id,
				nodeID: context.getters.getCurrentNodeBlogContent.nodeID,
				featuredImageURL:
					context.getters.getCurrentNodeBlogContent.featuredImageURL,
				blogHTML: blogHTML.documentElement.innerHTML,
				reviewed: context.getters.getCurrentNodeBlogContent.reviewed,
				title: context.getters.getCurrentNodeBlogContent.title,
				date: context.getters.getCurrentNodeBlogContent.date,
			};
			let updatedNodeBlogContent = JSON.stringify(nodeBlogContentOBJ);
			const params = new URLSearchParams();
			params.append(
				"scheduledBlogPost",
				JSON.stringify(context.getters.getScheduledBlogPosts)
			);
			params.append("clientSlug", context.getters.getClientSlug);
			params.append("updatedNode", updatedNode);
			params.append("updatedNodeBlogContent", updatedNodeBlogContent);
			await axios.post(
				"/api/clients/contentTree/updateNodeBlogContent",
				params,
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			);
			await axios.post(
				"/api/clients/contentTree/updateScheduledBlogPost",
				params,
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			);
			alert("This blog post has been scheduled");
		} catch (error) {
			console.log("=== scheduleBlogPost catch error ===", error);
		}
	},
	async mapContentTreeToList(context) {
		const nodes = context.getters.getClientContentTree.nodes;
		console.log("mapContentTreeToList action:", nodes);
		let arr = [];
		// Create a map of nodes by their id
		const nodeMap = new Map();
		nodes.forEach((node) => {
			nodeMap.set(node.id, {
				id: node.id,
				name: node.name,
				value: node.title,
				parent: node.parent,
				level: node.level,
				status: node.status ? node.status.toLowerCase() : "",
				reach: node.reach,
				category: node.category,
				accumulativeReach: node.accumulativeReach,
				elements: [],
			});
		});
		// Add elements to their respective parent nodes
		nodes.forEach((node) => {
			if (node.parent !== "root") {
				const parentNode = nodeMap.get(node.parent);
				if (parentNode) {
					parentNode.elements.push(nodeMap.get(node.id));
					// Sort the elements array based on the 'name' property
					parentNode.elements.sort((a, b) => {
						// Split 'name' by '.' and compare each part for proper comparison
						const aNameParts = a.name.split(".");
						const bNameParts = b.name.split(".");
						for (
							let i = 0;
							i < Math.min(aNameParts.length, bNameParts.length);
							i++
						) {
							if (aNameParts[i] !== bNameParts[i]) {
								return parseInt(aNameParts[i]) - parseInt(bNameParts[i]);
							}
						}
						return aNameParts.length - bNameParts.length;
					});
				} else {
					console.log(
						`Node ${node.id} has no parent - Requires Parent - ISSUE`
					);
				}
			}
		});
		nodes.forEach((node) => {
			if (node.parent === "root") {
				arr.push(nodeMap.get(node.id));
			}
		});
		console.log("map content list2", arr);
		arr[0].value = context.getters.getClientSlug;
		console.log("mapContentTreeToList action after:", arr);
		return arr;
	},
	async changeNode(context, data) {
		console.log("changeNode", data);
		try {
			//Update Parent and Change index number of node.
			await context.commit("setChangeMovementOfNode", data);
			await context.dispatch("saveClientContentTree");
			const newArr = await context.dispatch("mapContentTreeToList");
			return newArr;
		} catch (error) {
			console.log("changeNode error catch", error);
		}
	},
	async getGoogleAuthAccountIDs(context) {
		try {
			context.commit("setSocial", {
				prop: "tokens/google/accessToken",
				val: context.getters.getSocial.tokens.google.accessToken,
			});
			// const accessToken = context.getters.getSocial.tokens.google.accessToken;
			const response1 = await axios.get(
				`https://www.googleapis.com/analytics/v3/management/accounts`,
				{
					headers: {
						Authorization: `Bearer ${context.getters.getSocial.tokens.google.accessToken}`,
					},
				}
			);
			// Sort the items array by the name property
			response1.data.items.sort((a, b) => a.name.localeCompare(b.name));
			context.commit("setSocial", {
				prop: "tokens/google/userLinkedCompanyAccounts",
				val: response1.data.items,
			});
		} catch (error) {
			console.log("getGoogleAuthAccountIDs error catch", [error]);
		}
	},
	async getGoogleAuthGA4PropertyIDs(context) {
		try {
			const accessToken = context.getters.getSocial.tokens.google.accessToken;
			const accountID = context.getters.getSocial.tokens.google.accountID;
			const params = new URLSearchParams();
			console.log("tokens", context.getters.getSocial.tokens);
			params.append("accountID", accountID);
			params.append("accessToken", accessToken);
			console.log("======= getGoogleAuthGA4PropertyIDs ======", accessToken);
			const response = await axios.post(
				"/api/google/getGA4PropertyIDs",
				params,
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			);
			if (response.data === "No access token provided") {
				alert(`Error: No access token: ${accessToken}`);
			} else {
				context.commit("setSocial", {
					prop: "tokens/google/userLinkedProperties",
					val: response.data,
				});
			}
		} catch (error) {
			console.log("getGoogleAuthGA4PropertyIDs error catch", [error]);
		}
	},
	async deleteSocialAccount(context, data) {
		const tokenClass = new SocialTokens();
		const params = new URLSearchParams();

		params.append("socialItem", data.socialItem);
		params.append("username", data.username);
		params.append("socialItemClass", JSON.stringify(tokenClass));

		await axios.post("/api/clients/deleteSocialTokens", params, {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
		});
		console.log("delete user tokens:", data.username);
		const dbResponseTokens = await axios.get(
			`/api/clients/getSocialTokens/${data.username}`
		);
		console.log("updated user tokens from db:", dbResponseTokens.data);
		context.commit("setSocial", { prop: "tokens", val: dbResponseTokens.data });
		await context.dispatch("getSocialAccountStatus");
	},
	async checkIfNodeExsists(context) {
		try {
			const title =
				context.getters.getCurrentNode.fieldGroups.title.fields.title.value;
			if (title === "") {
				return false;
			} else {
				return true;
			}
		} catch (error) {
			console.log(error);
		}
	},
	async getSocialAccountStatus(context) {
		try {
			let platforms = [];

			if (context.getters.getSocial.tokens.meta.longliveAccessToken) {
				platforms.push("meta");
			} else {
				console.log("meta-no-token: longliveAccessToken");
			}
			if (context.getters.getSocial.tokens.twitter.accessToken) {
				platforms.push("twitter");
			} else {
				console.log("twitter-no-token: accessToken");
			}
			if (context.getters.getSocial.tokens.linkedin.accessToken) {
				platforms.push("linkedin");
			} else {
				console.log("linkedin-no-token: accessToken");
			}
			if (context.getters.getSocial.tokens.google.accessToken) {
				platforms.push("google");
			} else {
				console.log("google-no-token: accessToken");
			}
			if (context.getters.getSocial.tokens.wordpress.url) {
				platforms.push("wordpress");
			} else {
				console.log("wordpress-no-token: url");
			}

			if (platforms.length > 0) {
				context.commit("setSocial", {
					prop: `socialAccountInformation`,
					val: new SocialAccountInformation(),
				});
				let promises = platforms.map(async (platform) => {
					const params = new URLSearchParams();
					params.append("platform", platform);
					params.append("slug", context.getters.getClientSlug);
					const data = await axios.post(
						"/api/clients/getSocialAccountStatus",
						params,
						{
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					);
					console.log("the data response:", [platform, data]);
					if (context.getters.getSocial.post.error) {
						if (platform === "meta") {
							if (!data.fbPageName) {
								context.commit("setSocial", {
									prop: "post/scheduleTo/facebook",
									val: false,
								});
							}
							if (!data.instaPageName) {
								context.commit("setSocial", {
									prop: "post/scheduleTo/instagram",
									val: false,
								});
							}
						}
						if (platform === "twitter") {
							if (!data.name) {
								context.commit("setSocial", {
									prop: "post/scheduleTo/twitter",
									val: false,
								});
							}
						}
						if (platform === "linkedin") {
							if (!data.pageName) {
								context.commit("setSocial", {
									prop: "post/scheduleTo/linkedin",
									val: false,
								});
							}
						}
					}
					context.commit("setSocial", {
						prop: `socialAccountInformation/${platform}`,
						val: data.data,
					});
				});

				await Promise.all(promises);
			} else {
				context.commit("setSocial", {
					prop: `socialAccountInformation`,
					val: new SocialAccountInformation(),
				});
			}
		} catch (error) {
			console.log("getSocialAccountStatus catch error", error);
		}
	},
	async isNodePosting(context) {
		try {
			let errorMessage = "";
			if (context.getters.getNodeWasOrIsPosting.status === false) {
				let oldIsPublishedStatus = "";
				if (
					context.getters.getCurrentNode.fieldGroups.format.fields.format
						.value === "Blog"
				) {
					oldIsPublishedStatus =
						context.getters.getCurrentNodeBlogContent.hasbeenPublished;
				} else if (
					context.getters.getCurrentNode.fieldGroups.format.fields.format
						.value === "Social"
				) {
					oldIsPublishedStatus =
						context.getters.getSocial.post.hasbeenPublished;
				}
				const params = new URLSearchParams();
				params.append("nodeId", context.getters.getCurrentNode.id);
				params.append(
					"format",
					context.getters.getCurrentNode.fieldGroups.format.fields.format.value
				);
				params.append("oldIsPublishedStatus", oldIsPublishedStatus);
				params.append("authUsername", context.getters.getAuthUsername);
				params.append("client", context.getters.getClientSlug);
				const [isNodePosting, lastChangeToContentTree] = await Promise.all([
					axios.post("/api/clients/isNodePosting", params, {
						headers: {
							"Content-Type": "application/x-www-form-urlencoded",
						},
					}),
					axios.post("/api/clients/lastChangeToContentTree", params, {
						headers: {
							"Content-Type": "application/x-www-form-urlencoded",
						},
					}),
				]);
				if (
					isNodePosting.data === true ||
					lastChangeToContentTree.data === true
				) {
					if (isNodePosting.data) {
						errorMessage =
							"Node is Posting or was posted. Please reload in a moment";
					}
					if (lastChangeToContentTree.data) {
						errorMessage =
							"A user has made updates to the content tree. Please reload to show latest version.";
					}
					context.commit("setNodeWasOrIsPosting", {
						status: true,
						error: errorMessage,
					});
				}
			}
			return context.getters.getNodeWasOrIsPosting; // we need the user to reload the page if they recieve this as if user is editing node, we cant just allow it later to save.
		} catch (error) {
			console.log("isNodePosting catch error", error);
		}
	},
	async checkIfOldNodeExsists(context) {
		try {
			const id = context.getters.getCurrentNode.id;
			const urlParams = new URLSearchParams(window.location.search);
			const nodeId = urlParams.get("node");
			//User has gone into creating a new node but has decided
			//to not save it so we must pull the old node data back as it uses the same store.
			if (id === "" && nodeId) {
				//1. in the url, there is a param called 'node' We need the value from this.
				const selectedNodeData = await axios.get(
					`/api/clients/getCurrentNode/${nodeId}`
				);
				const response = await axios.get(
					`/api/clients/findNodeBlogContent/${nodeId}`
				);
				const commentResponse = await axios.get(
					`/api/clients/getComments/${nodeId}`
				);
				context.commit("setIsCreatingNewNodeToAddToDB", false);
				context.commit("setIsCreatingNewNode", false);
				if (response.data) {
					context.commit("setNodeBlogContent", response.data);
					context.commit("setClientNodeComments", commentResponse.data);
				} else {
					context.commit("setNodeBlogContentID", nodeId);
					context.commit("setClientNodeComments", []);
				}

				// Setting node in vuex
				await context.commit("setNode", selectedNodeData.data);
			}
		} catch (error) {
			console.log("check if node exsists", error);
		}
	},
	async generateTestNodes(context) {
		try {
			const params = new URLSearchParams();
			params.append("slug", context.getters.getClientSlug);
			const data = await axios.post("/api/clients/generateTestNodes", params, {
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
			});
			if (data) {
				console.log("generateTestNodes recieved data:", data);
			}
		} catch (error) {
			console.log("generateTestNodes error", error);
		}
	},
	async scrapeSocialPlatforms(context, data) {
		console.log("scrapeSocialPlatforms starting");

		try {
			const params = new URLSearchParams();
			params.append("slug", context.getters.getClientSlug);
			params.append("platform", data.platform);
			if (data.fields) {
				params.append("fields", data.fields);
			}
			const response = await axios.post(
				"/api/clients/scrapeSocialPlatforms",
				params,
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			);
			if (response) {
				await context.dispatch("getImportedContentTree");
				location.reload();
				return true;
			} else {
				alert("scrapeSocialPlatforms has recieved no data");
				location.reload();
				return false;
			}
		} catch (error) {
			console.log("generateTestNodes error", error);
		}
	},
	async getImportedContentTree(context, _slug = "") {
		try {
			const slug = _slug === "" ? context.getters.getClientSlug : _slug;
			console.log("getImportedContentTree starting", slug);
			const params = new URLSearchParams();
			params.append("slug", slug);
			const response = await axios.post(
				"/api/clients/getImportedContentTree",
				params,
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			);
			const nodes = response.data.nodes;
			if (nodes) {
				context.commit("setImportedContentTree", { nodes: nodes, links: [] });
			} else {
				console.log("getImportedContentTree has recieved no data", response);
			}
		} catch (error) {
			console.log("getImportedContentTree action catch error", error);
		}
	},
	async getWordpressACFfields(context) {
		console.log("getWordpressACFfields starting");
		try {
			const params = new URLSearchParams();
			params.append("slug", context.getters.getClientSlug);
			const response = await axios.post(
				"/api/clients/getWordpressACFfields",
				params,
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			);
			console.log("getWordpressACFfields have data:", response.data);
			context.commit("setACFPickerData", response.data);
			context.commit("setSocial", { prop: "ACFModalIsOpen", val: true });
			context.commit("setHidePageLoader", true);
		} catch (error) {
			console.log("generateTestNodes error", error);
		}
	},
	async reportProblem(context) {
		const problem = context.getters.getReportProblem;
		try {
			const form = new FormData();
			form.append("user", problem.user); //string
			form.append("clientSystem", problem.clientSystem); //string
			form.append("type", problem.type); //string
			form.append("screenshot", problem.screenshot); //image file
			form.append("clientSlug", problem.clientSlug); //string
			form.append("currentNode", problem.currentNode); //string
			form.append("currentURL", problem.currentURL); //string
			form.append("type", problem.type); //string
			form.append("action", problem.action); //string
			form.append("moreInfo", problem.moreInfo); //string
			form.append("title", problem.title); //string
			console.log("reportProblem starting", problem);
			if (problem.title === "" || problem.moreInfo === "") {
				alert(
					`The title and description fields are required. Please fill them in.`
				);
			} else {
				const response = await axios.post("/api/clients/reportProblem", form, {
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
				});
				console.log("reportProblem response", response.data);
				alert(`Thank you, your problem has been reported.`);
				context.commit("resetReportProblem");
			}
		} catch (error) {
			console.log("reportProblem error", error);
			alert(
				`Sorry, we seem to be unable to report your problem. Please try emailing us instead. The error: ${error}`
			);
			context.commit("resetReportProblem");
		}
	},
	async deleteAccountData(context, accountType) {
		console.log("deleteAccountData starting", [
			context.getters.getClientSlug,
			accountType,
		]);
		try {
			const params = new URLSearchParams();
			params.append("accountType", "client");
			params.append("username", context.getters.getClientSlug);
			await axios.post("/api/clients/deleteAccountData", params, {
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
			});
			alert("Account has been removed");
			console.log("redirecting to: ", window.location.hostname);
			let port = window.location.port ? ":" + window.location.port : "";
			if (window.location.hostname === "localhost") {
				port = ":8080";
			}
			window.location.href =
				window.location.protocol + "//" + window.location.hostname + port;
		} catch (error) {
			console.log("deleteAccountData error", error);
			alert(
				`Sorry, we seem to be unable to delete Account Data. Please try submitting a bug report. The error: ${error}`
			);
		}
	},
	async testLink() {
		try {
			const data = await axios.post("http://localhost:1234/ai");
			console.log("testlink data:", data.data);
			alert("TEST LINK HIT");
		} catch (error) {
			console.log("testLink error", error);
		}
	},
	async createOrUpdateContentStrategyNode(context, data) {
		try {
			const node = data.node;

			const params = new URLSearchParams();
			params.append("slug", context.getters.getClientSlug);
			params.append("node", JSON.stringify(node));
			params.append("year", context.getters.getContentStrategy.year);
			params.append("month", context.getters.getContentStrategy.month);
			params.append("isEditing", context.getters.getContentStrategy.isEditing);
			params.append("isAdding", context.getters.getContentStrategy.isAdding);
			const response = await axios.post(
				"/api/clients/createOrUpdateContentStrategyNode",
				params,
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			);
			console.log("createOrUpdateContentStrategyNode response:", response.data);
			alert("Created/Updated Node Successfully");
			await context.dispatch("getAllContentStrategyNodes");
		} catch (error) {
			console.log("createOrUpdateContentStrategyNode error", error);
		}
	},
	async approveContentStrategyNodes(context) {
		try {
			const nodes = context.getters.getContentStrategy.approvedNodes;
			console.log("approve content strategy nodes:", nodes);
			const params = new URLSearchParams();
			params.append("slug", context.getters.getClientSlug);
			params.append("nodes", JSON.stringify(nodes));
			const response = await axios.post(
				"/api/clients/approveContentStrategyNodes",
				params,
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			);
			console.log("approveContentStrategyNodes response:", response);
			// Navigate to the 'content' route with query parameters format=false and list=true
			this.$router.push({
				name: "Content Strategy",
			});
		} catch (error) {
			console.log("createOrUpdateContentStrategyNode error", error);
		}
	},

	async deleteContentStrategyNode(context) {
		try {
			const params = new URLSearchParams();
			params.append("id", context.getters.id);

			const data = await axios.post(
				"/api/clients/deleteContentStrategyNode",
				params,
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			);
			console.log("deleteContentStrategyNode data:", data);
			alert("deleteContentStrategyNode done");
		} catch (error) {
			console.log("deleteContentStrategyNode error", error);
		}
	},
	async getAllContentStrategyNodes(context) {
		try {
			const params = new URLSearchParams();
			params.append("slug", context.getters.getClientSlug);
			params.append("year", context.getters.getContentStrategy.year);
			params.append("month", context.getters.getContentStrategy.month);

			const data = await axios.post(
				"/api/clients/getAllContentStrategyNodes",
				params,
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			);
			context.commit("setContentStrategy", { prop: "nodes", val: data.data });
			console.log("getAllContentStrategyNodes data:", data.data);
		} catch (error) {
			console.log("getAllContentStrategyNodes error", error);
		}
	},
	async getContentStrategyNode(id) {
		try {
			const params = new URLSearchParams();
			params.append("id", id);

			const data = await axios.post(
				"/api/clients/getContentStrategyNode",
				params,
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			);
			console.log("getContentStrategyNode data:", data);
			alert("getContentStrategyNode done");
		} catch (error) {
			console.log("getContentStrategyNode error", error);
		}
	},
	async onDeleteContentStrategyNode(context) {
		try {
			const params = new URLSearchParams();
			params.append("id", context.getters.getContentStrategy.currentNode);

			const data = await axios.post(
				"/api/clients/deleteContentStrategyNode",
				params,
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			);
			console.log("getContentStrategyNode data:", data);
			alert("Node has been deleted");
		} catch (error) {
			console.log("getContentStrategyNode error", error);
		}
	},
};

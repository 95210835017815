<template>
    <div class="blog-modal" v-if="getBlogModalOpen">

        <div class="blog-modal__modal">

            <button class="btn-primary btn-primary--outline" @click.prevent="onClose"><i class="fa-sharp fa-solid fa-arrow-left"></i>Back</button>

            <h2>{{ platformTitle }} {{platformTitle === 'Wordpress' ? 'Blog' : 'Account'}} Information</h2>
            
            <div v-if="loading === true"><h3 class="loading-text">Loading...</h3></div>
            <div class="blog-modal-wrap" v-if="loading === false">
            
                <div v-if="getBlogModalOpen === 'meta'">

                    <div class="items">
                        <span>meta user profile:</span>
                        <span class="input-style">{{ getSocial.socialAccountInformation.meta.username }}</span>
                        <span>Facebook Page name:</span>
                        <a  class="input-style" v-if="getSocial.socialAccountInformation.meta.fbPageLink" :href="getSocial.socialAccountInformation.meta.fbPageLink" target="_blank">
                            <span>{{ getSocial.socialAccountInformation.meta.fbPageName }}</span>
                        </a>
                        <span class="input-style" v-if="!getSocial.socialAccountInformation.meta.fbPageLink"></span>
                        <span>instagram Page name:</span>
                        <a class="input-style" v-if="getSocial.socialAccountInformation.meta.instaPageLink" :href="getSocial.socialAccountInformation.meta.instaPageLink" target="_blank">
                            <span>{{ getSocial.socialAccountInformation.meta.instaPageName }}</span>
                        </a>
                        <span class="input-style" v-if="!getSocial.socialAccountInformation.meta.instaPageLink"></span>
                        <span>Page Token Status:</span>
                        <span class="input-style">{{ getSocial.socialAccountInformation.meta.fbPageTokenStatus.data_access_expires_at }}</span>
                        <span>User Token Status:</span>
                        <span class="input-style">{{ getSocial.socialAccountInformation.meta.fbUserTokenStatus.data_access_expires_at }}</span>
                    </div>

                    <p class="blog-modal-alert" v-if="!getSocial.socialAccountInformation.meta.fbPageTokenStatus.is_valid && getSocial.socialAccountInformation.meta.username">
                        <span>Page Token has problem</span>
                    </p>
                    <p class="blog-modal-alert" v-if="!getSocial.socialAccountInformation.meta.fbUserTokenStatus.is_valid && getSocial.socialAccountInformation.meta.username">
                        <span>User Token has problem</span>
                    </p>
                    <p class="blog-modal-alert">
                        <span>Select the option to Opt in to all current and future Businesses</span>
                    </p>

                    <div class="blog-modal-nav-footer">
                        <button @click.prevent="authenticatePlatform('meta')" class="btn-save"><i class="fa-solid fa-upload"></i>Connect</button>
                        <button @click.prevent="this.$store.dispatch('client/deleteSocialAccount', {socialItem: 'meta', username: this.getClientSlug})" class="btn-close"><i class="fa-solid fa-arrow-rotate-left"></i>Reset</button>
                    </div>

                </div>

                <div v-if="getBlogModalOpen === 'twitter'">

                    <div class="items">
                        <span>X Name:</span>
                        <span class="input-style">{{ getSocial.socialAccountInformation.twitter.name }}</span>
                        <span>X page:</span>
                        <a class="input-style" v-if="getSocial.socialAccountInformation.twitter.link" :href="getSocial.socialAccountInformation.twitter.link" target="_blank">
                            <span>{{ getSocial.socialAccountInformation.twitter.username }}</span>
                        </a>
                        <span class="input-style" v-if="!getSocial.socialAccountInformation.twitter.link"></span>
                    </div>

                    <p class="blog-modal-alert" v-if="getSocial.socialAccountInformation.twitter.error">
                        <span>{{ getSocial.socialAccountInformation.twitter.error.title }}</span>
                    </p>

                    <div class="blog-modal-nav-footer">
                        <button @click.prevent="authenticatePlatform('twitter')" class="btn-save"><i class="fa-solid fa-upload"></i>Connect</button>
                        <button @click.prevent="this.$store.dispatch('client/deleteSocialAccount', {socialItem: 'twitter', username: this.getClientSlug})" class="btn-close"><i class="fa-solid fa-arrow-rotate-left"></i>Reset</button>
                    </div>

                </div>

                <div v-if="getBlogModalOpen === 'linkedin'">
                    
                    <div class="items">
                        <span>linkedin user profile:</span>
                        <span class="input-style">{{ getSocial.socialAccountInformation.linkedin.name }}</span>
                        <span>linkedin Page name:</span>
                        <a class="input-style" v-if="getSocial.socialAccountInformation.linkedin.link" :href="getSocial.socialAccountInformation.linkedin.link" target="_blank">
                           {{ getSocial.socialAccountInformation.linkedin.pageName }}
                        </a>
                        <span class="input-style"  v-if="!getSocial.socialAccountInformation.linkedin.link"></span>
                    </div>

                    <div class="blog-modal-nav-footer">
                        <button @click.prevent="authenticatePlatform('linkedin')" class="btn-save"><i class="fa-solid fa-upload"></i>Connect</button>
                        <button @click.prevent="this.$store.dispatch('client/deleteSocialAccount', {socialItem: 'linkedin', username: this.getClientSlug})" class="btn-close"><i class="fa-solid fa-arrow-rotate-left"></i>Reset</button>
                    </div>

                </div>

                <div v-if="getBlogModalOpen === 'google'">

                    <div class="items">
                        <span>Account name:</span>
                        <div class="google-input-wrap">
                            <span class="input-style">{{ getSocial.socialAccountInformation.google.name }}</span>
                            <!-- <button @click.prevent="onEditField('google-name')" v-if="getSocial.socialAccountInformation.google.name" class="btn-close"><i class="fa-solid fa-arrow-rotate-left"></i>Reset</button> -->
                        </div>
                        <span>GA4 Property:</span>
                        <div class="google-input-wrap">
                            <span class="input-style">{{ getSocial.socialAccountInformation.google.GA4PropertyName }}</span>
                            <!-- <button @click.prevent="onEditField('GA4PropertyName')" v-if="getSocial.socialAccountInformation.google.GA4PropertyName" class="btn-close"><i class="fa-solid fa-arrow-rotate-left"></i>Reset</button> -->
                        </div>
                        <span>Ad account ID:</span>
                        <div class="google-input-wrap">
                            <input :disabled="this.disabledAdsAccountID" type="text" name="adsAccountID" :placeholder="getSocial.tokens.google.adsAccountID || '095839009'" class="input-style" :v-bind="getSocial.tokens.google.adsAccountID" :class="{'placeholder': !getSocial.tokens.google.adsAccountID}" @input="changeInput('adsAccountID', $event)">
                            <button @click.prevent="resetInput('adsAccountID')" v-if="showResetAdsAccountID" class="btn-close"><i class="fa-solid fa-arrow-rotate-left"></i>Reset</button>
                            <button @click.prevent="onEditField('adsAccountID')" v-if="showAddAdsAccountID" class="btn-save btn-save--outline"><i class="fa-solid fa-pen-to-square"></i>Add</button>
                            <button @click.prevent="saveInput()" v-if="showSaveAdsAccountID" class="btn-save"><i class="fa-solid fa-upload"></i>Save</button>
                        </div>
                        <span>Agency Campaign:</span>
                        <div class="google-input-wrap">
                            <input :disabled="this.disabledAgencyCampaign" type="text" name="agencyCampaign" :placeholder="getSocial.tokens.google.agencyCampaign || '075693824'" class="input-style" :v-bind="getSocial.tokens.google.agencyCampaign" :class="{'placeholder': !getSocial.tokens.google.agencyCampaign}" @input="changeInput('agencyCampaign', $event)">
                            <button @click.prevent="resetInput('agencyCampaign')" v-if="showResetAgencyCampaign" class="btn-close"><i class="fa-solid fa-arrow-rotate-left"></i>Reset</button>
                            <button @click.prevent="onEditField('agencyCampaign')" v-if="showAddAgencyCampaign" class="btn-save btn-save--outline"><i class="fa-solid fa-pen-to-square"></i>Add</button>
                            <button @click.prevent="saveInput()" v-if="showSaveAgencyCampaign" class="btn-save"><i class="fa-solid fa-upload"></i>Save</button>
                        </div>
                        <span>Search Console URL:</span>
                        <div class="google-input-wrap">
                            <input :disabled="this.disabledSearchConsoleURL" type="text" name="searchConsoleURL" placeholder="http://www.placeholder-site.com" :value="getSocial.tokens.google.searchConsoleURL" class="input-style" :class="{'placeholder': !getSocial.tokens.google.searchConsoleURL}" @input="changeInput('searchConsoleURL', $event)">
                            <button @click.prevent="resetInput('searchConsoleURL')" v-if="showResetSearchConsoleURL" class="btn-close"><i class="fa-solid fa-arrow-rotate-left"></i>Reset</button>
                            <button @click.prevent="onEditField('searchConsoleURL')" v-if="showAddSearchConsoleURL" class="btn-save btn-save--outline"><i class="fa-solid fa-pen-to-square"></i>Add</button>
                            <button @click.prevent="saveInput()" v-if="showSaveSearchConsoleURL" class="btn-save"><i class="fa-solid fa-upload"></i>Save</button>
                        </div>
                    </div>

                    <p class="blog-modal-alert" v-if="getSocial.socialAccountInformation.google.seoValid === false && getSocial.tokens.google.agencyCampaign !== ''">The agency Campaign is not valid</p>
                    <p class="blog-modal-alert" v-if="getSocial.socialAccountInformation.google.googleError === true && getSocial.socialAccountInformation.google.name">Google Token has an error - Please re-authenticate</p>

                    <div class="blog-modal-nav-footer">
                        <button v-if="!getSocial.socialAccountInformation.google.GA4PropertyName" @click.prevent="authenticatePlatform('google')" class="btn-save"><i class="fa-solid fa-upload"></i>Connect</button>
                        <button v-else @click.prevent="this.$store.dispatch('client/deleteSocialAccount', {socialItem: 'google', username: this.getClientSlug})" class="btn-close"><i class="fa-solid fa-arrow-rotate-left"></i>Reset</button>
                    </div>

                </div>

                <div v-if="getBlogModalOpen === 'wordpress'">

                    <div class="blog-modal-fields">
                        <label for="">URL:</label>
                        <input type="text" name="url" id="" placeholder="http://www.roostermarketing.com" :value="getSocial.tokens.wordpress.url" @input="updateStore('url', $event.target.value)">
                        <label for="">Username:</label>
                        <input type="text" name="username" id="" :value="getSocial.tokens.wordpress.username" @input="updateStore('username', $event.target.value)">
                        <label for="">Password:</label>
                        <input type="password" name="password" id="" :value="getSocial.tokens.wordpress.password" @input="updateStore('password', $event.target.value)">

                    </div>
                    <p class="blog-modal-alert" v-if="wpConnected">Connection successful</p>
                    <p class="blog-modal-alert" v-if="noConnection">Connection could not be made</p>

                    <p class="blog-modal-alert" v-if="canExportButCantImport">You can only share to wordpress website</p>
                    <p class="blog-modal-alert" v-if="cantExportButCanImport">You are only allowed to import posts</p>

                    <div class="blog-modal-nav-footer">
                        <button @click.prevent="this.$store.dispatch('client/updateSocialTokens')" class="btn-save wordpress-social-btn-link"><i class="fa-solid fa-upload"></i>Connect</button>
                        <button @click.prevent="this.$store.dispatch('client/deleteSocialAccount', {socialItem: 'wordpress', username: this.getClientSlug})" class="btn-close"><i class="fa-solid fa-arrow-rotate-left"></i>Reset</button>
                    </div>


                </div>

            </div>

        </div>

    </div>

</template>
<script>
import {mapGetters} from "vuex"
export default {
    mounted(){
        const url = new URL(window.location.href);
        const showGoogle = url.searchParams.get('google-next-stage') === 'true';
        if(showGoogle){
            this.loading = true;
            this.$store.commit('client/setSocial', {prop: 'modalIsOpen', val: true})
            this.$store.commit('client/setBlogModalOpen', 'google');
        }
    },
    data(){
        return{
            loading: true,
            disabledAdsAccountID: true,
            disabledAgencyCampaign: true,
            disabledSearchConsoleURL: true,


            showResetAdsAccountID: false,
            showAddAdsAccountID: true,
            showSaveAdsAccountID: false,

            showResetAgencyCampaign: false,
            showAddAgencyCampaign: true,
            showSaveAgencyCampaign: false,
        
            showResetSearchConsoleURL: false,
            showAddSearchConsoleURL: true,
            showSaveSearchConsoleURL: false,

        }
    },
    computed:{
        ...mapGetters("client", ["getClientSlug", 'getSocial', 'getBlogModalOpen', 'getCurrentNode']),
        ...mapGetters("auth", ["getAuthUsername"]),
        platformTitle(){
            let title = '';
            if(this.getBlogModalOpen === 'meta'){
                title = 'Meta';
            }
            if(this.getBlogModalOpen === 'twitter'){
                title = 'X';
            }
            if(this.getBlogModalOpen === 'linkedin'){
                title = 'Linkedin';
            }
            if(this.getBlogModalOpen === 'google'){
                title = 'Google';
            }
            if(this.getBlogModalOpen === 'wordpress'){
                title = 'Wordpress';
            }
            return title; 
        },
        cantExportButCanImport() {
        return this.getSocial.socialAccountInformation.wordpress.wordpressIsValid === false 
            && this.getSocial.socialAccountInformation.wordpress.getPostsWorks === true 
            && this.getSocial.tokens.wordpress.url 
            && this.getSocial.tokens.wordpress.username;
        },
        canExportButCantImport(){
            return this.getSocial.socialAccountInformation.wordpress.wordpressIsValid === true 
            && this.getSocial.socialAccountInformation.wordpress.getPostsWorks === false 
            && this.getSocial.tokens.wordpress.url 
            && this.getSocial.tokens.wordpress.username;
        },
        noConnection(){
            return this.getSocial.socialAccountInformation.wordpress.wordpressIsValid === false 
            && this.getSocial.socialAccountInformation.wordpress.getPostsWorks === false 
            && this.getSocial.tokens.wordpress.url 
            && this.getSocial.tokens.wordpress.username;
        },
        wpConnected(){
            return this.getSocial.socialAccountInformation.wordpress.wordpressIsValid === true 
            && this.getSocial.socialAccountInformation.wordpress.getPostsWorks === true 
            && this.getSocial.tokens.wordpress.url 
            && this.getSocial.tokens.wordpress.username;
        },
    },
    methods:{
        updateStore(name, val){
            const value = val.endsWith('/') && name === 'url' ? val.slice(0, -1) : val;
            this.$store.commit('client/setSocial', {prop: `tokens/wordpress/${name}`, val: value})
        },
        async authenticatePlatform(platform){
            try{

                this.loading = true;

                await this.$store.dispatch('client/deleteSocialAccount', {socialItem: platform, username: this.getClientSlug});

                if(platform === 'meta'){
                    window.location.href = `/api/auth/facebook?client=${this.getClientSlug}&node=${this.getCurrentNode.id}`
                }
                if(platform === 'twitter'){
                    window.location.href =`/api/auth/twitter?client=${this.getClientSlug}&node=${this.getCurrentNode.id}`
                }
                if(platform === 'linkedin'){
                    window.location.href = `/api/auth/linkedin?client=${this.getClientSlug}&node=${this.getCurrentNode.id}`
                }
                if(platform === 'google'){
                    window.location.href = `/api/auth/google?client=${this.getClientSlug}`
                }
            }catch(error){
                console.log('authenticatePlatform error', error);
            }
        },
        onClose(){
            this.$store.commit('client/setBlogModalOpen', false)
        },
        onEditField(field){
            if(field === 'adsAccountID'){
                this.disabledAdsAccountID = !this.disabledAdsAccountID;
                this.showAddAdsAccountID = false;
                this.showSaveAdsAccountID = true;
            }
            if(field === 'agencyCampaign'){
                this.disabledAgencyCampaign = !this.disabledAgencyCampaign;
                this.showAddAgencyCampaign = false;
                this.showSaveAgencyCampaign = true;
            }
            if(field === 'searchConsoleURL'){
                this.disabledSearchConsoleURL = !this.disabledSearchConsoleURL;
                this.showAddSearchConsoleURL = false;
                this.showSaveSearchConsoleURL = true;
            }
        },
        async saveInput(){
            try{
                this.$store.commit('client/setHidePageLoader', false);
                await this.$store.dispatch('client/updateSocialTokens');
                this.userJustSaved(this.getSocial.tokens.google);
                alert('This has been saved');
                this.$store.commit('client/setHidePageLoader', true);
            }catch(error){
                console.log('saveInput blogModal catch error', error);
            }
        },
        userJustSaved(val){
            if(val.searchConsoleURL.length > 0){
                this.showAddSearchConsoleURL = false;
                this.showResetSearchConsoleURL = true;
            }else{
                if(this.showSaveSearchConsoleURL === false){
                    this.showAddSearchConsoleURL = true;
                }
            }
            if(val.agencyCampaign.length > 0){
                this.showAddAgencyCampaign = false;
                this.showResetAgencyCampaign = true;
            }else{
                if(this.showSaveAgencyCampaign === false){
                    this.showAddAgencyCampaign = true;
                }
            }
            if(val.adsAccountID.length > 0){
                this.showAddAdsAccountID = false;
                this.showResetAdsAccountID = true;
            }else{
                if(this.showSaveAdsAccountID === false){
                    this.showAddAdsAccountID = true;
                }
            }

            this.showSaveAdsAccountID = false;
            this.showSaveAgencyCampaign = false;
            this.showSaveSearchConsoleURL = false;
        },
        changeInput(field, event){
            if(field === 'adsAccountID'){
                this.$store.commit('client/setSocial', {prop: `tokens/google/adsAccountID`, val: event.target.value})
            }
            if(field === 'agencyCampaign'){
                this.$store.commit('client/setSocial', {prop: `tokens/google/agencyCampaign`, val: event.target.value})
            }
            if(field === 'searchConsoleURL'){
                this.$store.commit('client/setSocial', {prop: `tokens/google/searchConsoleURL`, val: event.target.value})
            }
        },
        async resetInput(field){
            try{
                this.$store.commit('client/setHidePageLoader', false);
                if(field === 'adsAccountID'){
                    this.disabledAdsAccountID = true;
                    this.showAddAdsAccountID = true;
                    this.showResetAdsAccountID = false;
                    this.showSaveAdsAccountID = false;
                    this.$store.commit('client/setSocial', {prop: `tokens/google/adsAccountID`, val: ''})
                }
                if(field === 'agencyCampaign'){
                    this.disabledAgencyCampaign = true;
                    this.showAddAgencyCampaign = true;
                    this.showResetAgencyCampaign = false;
                    this.showSaveAgencyCampaign = false;
                    this.$store.commit('client/setSocial', {prop: `tokens/google/agencyCampaign`, val: ''})
                }
                if(field === 'searchConsoleURL'){
                    this.disabledSearchConsoleURL = true;
                    this.showAddSearchConsoleURL = true;
                    this.showResetSearchConsoleURL = false;
                    this.showSaveSearchConsoleURL = false;
                    this.$store.commit('client/setSocial', {prop: `tokens/google/searchConsoleURL`, val: ''})
                }
                await this.$store.dispatch('client/updateSocialTokens');
                this.$store.commit('client/setHidePageLoader', true);
                alert('Data has been reset');
            }catch(error){
                console.log('resetInput BlogModal catch error', error);
            }
        },
        resetGoogleParam(){
             // Get the current URL
             let url = new URL(window.location.href);
            // Get the search parameters from the URL
            let params = new URLSearchParams(url.search);
            // Remove the 'googleModal' parameter
            params.delete('google-next-stage');
            // Set the search parameters back to the URL
            url.search = params.toString();
            // Change the current URL without reloading
            history.pushState({}, '', url.toString());
        },
        checkIfGoogleHasContentInFields(val, index){
            console.log('ddddddd', [val, index]);
            if(val.searchConsoleURL.length > 0 && this.showSaveSearchConsoleURL === false){
                this.showAddSearchConsoleURL = false;
                this.showResetSearchConsoleURL = true;
            }else{
                if(this.showSaveSearchConsoleURL === false){
                    this.showAddSearchConsoleURL = true;
                }
            }
            if(val.agencyCampaign.length > 0 && this.showSaveAgencyCampaign === false){
                this.showAddAgencyCampaign = false;
                this.showResetAgencyCampaign = true;
            }else{
                if(this.showSaveAgencyCampaign === false){
                    this.showAddAgencyCampaign = true;
                }
            }
            if(val.adsAccountID.length > 0 && this.showSaveAdsAccountID === false){
                this.showAddAdsAccountID = false;
                this.showResetAdsAccountID = true;
            }else{
                if(this.showSaveAdsAccountID === false){
                    this.showAddAdsAccountID = true;
                    console.log('s-1', [1, this.showAddAdsAccountID])
                }
            }
        },
        hideGoogleButtons(val){

            const url = new URL(window.location.href);
            const showGoogle = url.searchParams.get('google-next-stage') === 'true';

            if(val.GA4PropertyID == false && showGoogle == false){

                this.showAddAdsAccountID = false;
                this.showResetAdsAccountID = false;
                this.showSaveAdsAccountID = false;

                this.showAddAgencyCampaign = false;
                this.showResetAgencyCampaign = false;
                this.showSaveAgencyCampaign = false;

                this.showResetSearchConsoleURL =  false
                this.showAddSearchConsoleURL = false
                this.showSaveSearchConsoleURL = false

                this.$store.commit('client/setSocial', {prop: `tokens/google/adsAccountID`, val: ''})
                this.$store.commit('client/setSocial', {prop: `tokens/google/agencyCampaign`, val: ''})
                this.$store.commit('client/setSocial', {prop: `tokens/google/searchConsoleURL`, val: ''})
            }
        }
    },
    watch:{
        "getBlogModalOpen": {
            async handler() {
                const url = new URL(window.location.href);
                const showGoogle = url.searchParams.get('google-next-stage') === 'true';
                if(!showGoogle){
                    this.loading = false
                }
            },
        },
        "getSocial.socialAccountInformation.google": {
            async handler(val) {
                const url = new URL(window.location.href);
                const showGoogle = url.searchParams.get('google-next-stage') === 'true';
                if(val && showGoogle){
                    this.resetGoogleParam();
                    this.checkIfGoogleHasContentInFields(this.getSocial.tokens.google , '2');
                    this.loading = false
                }
            },
        },
        'getSocial.tokens.google': {
            handler: function (val) {
                if(val.GA4PropertyID){
                    this.checkIfGoogleHasContentInFields(val, '1');
                }else{
                    this.hideGoogleButtons(val);
                }

                console.log('the google data val:', val);
            },
            deep: true
        }
    },
}
</script>
<style lang="scss">
@import "./../../../src/assets/scss/components/blog.scss";
</style>
import axios from "axios";
import { conversionRate } from "../../../../../mixins/conversionRateFunc";
import { setGoogleData } from "../../../../../mixins/setGoogleDataFunc";
const dayMilliseconds = 86400000;

export default {
	async seoHeaderGraphBlockInit(context) {
		console.log(
			"seoHeaderGraphBlockInit starting:",
			context.getters.getSocial.tokens.google.agencyAPIkey
		);
		try {
			// API urls
			const metricClean = "googleRankingChanges"; // Note: sourced from agency analytics
			//   const agencyAnalyticsURL = `https://api.clientseoreport.com/v3/resources/rankings/campaign/date`; //https://api.agencyanalytics.com/v5/rankings/campaigns/{campaign_id}/dates/{date}
			// let goalsTotal = 0;
			// let createNewMetric = false;
			let updatedGoogleData;
			// let goalNames;
			// let goalValues;
			// let goalGraphNames;
			// let goalGraphValues;
			let runningTotalPrevious;
			let runningTotalCurrent;

			/*
			 * connect and grab the daily visitors for the last month, to create the graph.
			 */
			const response = await axios.get(
				`/api/clients/googleData/${context.getters.getClientSlug}`
			);

			updatedGoogleData = response.data;

			// let dailyHits = []
			// let dailyLabels = []
			let timeNow = Date.now();

			// If this is the first time it's been loaded there's nothing in the database so:
			// 1. set the time higher to ensure the API data is collected
			let timeUpdated = dayMilliseconds + 1;

			if (response.data.metrics[metricClean]) {
				// 2. If the data does exist replace the time updated
				timeUpdated = response.data.metrics[metricClean].updated;
			} else {
				// And if it doesn't add a placeholder for the new metric
				updatedGoogleData.metrics[metricClean] = {};
			}
			let timeSince = timeNow - timeUpdated;

			if (timeSince > dayMilliseconds) {
				/*
				 * The data is over a day old, so update from the API and save the updated information to the database.
				 */
				console.log("header graph updated SEO header over a day ago");
				var startDate = new Date();
				startDate.setDate(startDate.getDate() - 28);
				startDate = startDate.toISOString().slice(0, 10);
				var endDate = new Date();
				endDate.setDate(endDate.getDate());
				endDate = endDate.toISOString().slice(0, 10);

				const base64ApiKey = Buffer.from(
					context.getters.getSocial.tokens.google.agencyAPIkey
				);
				const res = await axios({
					method: "post",
					url: "https://apirequest.app/query",
					headers: {
						"Content-Type": "application/json",
						Authorization: base64ApiKey,
					},
					data: {
						provider: "agency-analytics-v2",
						asset: "campaign-rankings",
						operation: "read",
						fields: ["date", "google_ranking_change", "ranking_average"],
						filters: [
							{ end_date: { $lessthanorequal_comparison: endDate } },
							{ start_date: { $greaterthanorequal_comparison: startDate } },
							{
								campaign_id: {
									$equals_comparison:
										context.getters.getSocial.tokens.google.agencyCampaign,
								},
							},
						],
						group_by: ["date"],
						sort: [{ date: "asc" }],
						offset: 0,
						limit: 9999,
					},
				});

				let results = res.data.results.rows;

				for (var i = 0; i < results.length; i++) {
					var theDate = results[i].date;
					// console.log(theDate);
					updatedGoogleData.metrics[metricClean][theDate] =
						results[i].ranking_average;
				}
				// console.log(updatedGoogleData.metrics[metricClean])
				updatedGoogleData.metrics[metricClean].updated = Date.now();
				await setGoogleData(
					updatedGoogleData.metrics[metricClean],
					metricClean,
					context.getters.getClientSlug
				);
			}

			/*
			 * The data is less than a day old, so grab the data from the database.
			 */

			// Calculate the previous month
			for (var k = 56; k > 28; k--) {
				var savedDatePrev = new Date();
				savedDatePrev.setDate(savedDatePrev.getDate() - k);
				savedDatePrev = savedDatePrev.toISOString().slice(0, 10);

				if (
					typeof updatedGoogleData.metrics[metricClean][savedDatePrev] ===
					"number"
				) {
					runningTotalPrevious =
						runningTotalPrevious +
						updatedGoogleData.metrics[metricClean][savedDatePrev];
				}
			}

			context.commit("setComponents", {
				prop: "seo/HeaderGraphBlock/seoConversion",
				val:
					Math.round(runningTotalPrevious / 28) -
					Math.round(runningTotalCurrent / 28),
			});
			context.commit("setComponents", {
				prop: "seo/HeaderGraphBlock/seoConversion",
				val: conversionRate(context.getters.getComponents.seo.seoConversion),
			});
			return updatedGoogleData;
		} catch (error) {
			console.log("seoHeaderGraphBlockInit error init", error);
		}
	},
};

import axios from "axios";
import { setGoogleData } from "../../../../../mixins/setGoogleDataFunc";
const dayMilliseconds = 86400000;

export default {
	async SEOclicksImpressionsBlockInit(context) {
		try {
			const metricsURL = `https://www.googleapis.com/webmasters/v3/sites/https%3A%2F%2F${context.getters.getSocial.tokens.google.searchConsoleURL}/searchAnalytics/query?`;
			const metricClean = "clicksImpressions";
			const momDays = 56;
			let dailyClicks = [];
			let dailyImpressions = [];
			let dailyLabels = [];
			let updatedGoogleData;

			/*
			 * connect and grab the daily visitors for the last month, to create the graph.
			 */

			const response = await axios.get(
				`/api/clients/googleData/${context.getters.getClientSlug}`
			);

			updatedGoogleData = response.data;

			console.log(updatedGoogleData);

			let timeNow = Date.now();
			// If this is the first time it's been loaded there's nothing in the database so:
			// 1. set the time higher to ensure the API data is collected
			let timeUpdated = dayMilliseconds + 1;

			if (response.data.metrics[metricClean]) {
				// 2. If the data does exist replace the time updated
				timeUpdated = response.data.metrics[metricClean].updated;
			} else {
				// And if it doesn't add a placeholder for the new metric
				updatedGoogleData.metrics[metricClean] = {};
			}

			let timeSince = timeNow - timeUpdated;

			if (timeSince > dayMilliseconds) {
				console.log("updated 56 day organic sessions over a day ago");
				//var date = new Date();
				// Note: +2 days as search console seems to take a bit of time to update
				let promises = [];

				for (var i = momDays + 2; i > 2; i--) {
					let date = new Date();
					date.setDate(date.getDate() - i);
					let dateString = date.toISOString().slice(0, 10);

					console.log(dateString);

					promises.push(
						(async () => {
							const response = await axios({
								method: "post",
								url: metricsURL,
								params: {
									startDate: dateString,
									endDate: dateString,
								},
								headers: {
									Authorization:
										"Bearer " +
										context.getters.getSocial.tokens.google.accessToken,
									"developer-token": "I0zHnS4lyNgu-pUfz8r1Vw",
									"login-customer-id": "2714889262",
								},
							});
							const data = response.data;
							// Loop through and update the values if needed
							let clicks = data.rows[0].clicks;
							let impressions = data.rows[0].impressions;
							// The date is set here as by the time the axios response has fired the date var above will have changed
							updatedGoogleData.metrics[metricClean][dateString] =
								clicks + "," + impressions;
							updatedGoogleData.metrics[metricClean].updated = Date.now();
							await setGoogleData(
								JSON.stringify(updatedGoogleData),
								false,
								context.getters.getClientSlug
							);
						})()
					);
				}
				try {
					await Promise.all(promises);
				} catch (error) {
					console.error(error);
				}
			}

			/*
			 * The data is less than a day old, so grab the data from the database.
			 */
			// Note: +2 days as search console seems to take a bit of time to update
			for (var j = 32; j > 2; j--) {
				var savedDate = new Date();
				savedDate.setDate(savedDate.getDate() - j);
				let thedate = new Date(savedDate);
				savedDate = savedDate.toISOString().slice(0, 10);
				let mon = thedate.toLocaleString("en-UK", { month: "short" });
				let dd = thedate.getDate();
				let currentDaysClickImpressions =
					updatedGoogleData.metrics[metricClean][savedDate];
				// Check that the current hits amount is a number, incase it's not in the list for any reason.
				if (currentDaysClickImpressions) {
					dailyClicks.push(currentDaysClickImpressions.split(",")[0]);
					dailyImpressions.push(currentDaysClickImpressions.split(",")[1]);
					dailyLabels.push(mon + " " + dd);
				}
			}

			context.commit("setComponents", {
				prop: "seo/ClicksImpressionsBlock/dailyClicks",
				val: dailyClicks,
			});
			context.commit("setComponents", {
				prop: "seo/ClicksImpressionsBlock/dailyImpressions",
				val: dailyImpressions,
			});
			context.commit("setComponents", {
				prop: "seo/ClicksImpressionsBlock/dailyLabels",
				val: dailyLabels,
			});

			return true;
		} catch (error) {
			console.log("Error Message:", error.message);
			if (error.response) {
				console.log("error Response Data:", error.response.data);
				console.log("error Response Status:", error.response.status);
				console.log("error Response Headers:", error.response.headers);
			} else if (error.request) {
				console.log("error Request:", error.request);
			} else {
				console.log("error Error:", error.message);
			}
		}
	},
};

<template>
    <div v-if="getAIPopUpFields.modals.social || getAIPopUpFields.modals.blog" class="ai-pop-up-wrap" :class="{approvalFields : showApprovalFields, loading: this.showLoader}">
        <div class="ai-generate-fields" v-if="showApprovalFields === false && this.showLoader === false">
            <label for="keywords">Features and keywords</label>
            <textarea name="keywords" id="" cols="30" rows="3" :value="getAIPopUpFields.keywords" @input="this.$store.commit('client/setAIPopUpFields', {prop: 'keywords', val: $event.target.value})"></textarea>
            <label for="voice">Tone of voice</label>
            <select name="voice" id="" :value="getAIPopUpFields.voice" @input="this.$store.commit('client/setAIPopUpFields', {prop: 'voice', val: $event.target.value})">
                <option value="Expert">Expert</option>
                <option value="Daring">Daring</option>
                <option value="Playful">Playful</option>
                <option value="Sophisticated">Sophisticated</option>
                <option value="Persuasive">Persuasive</option>
                <option value="Supportive">Supportive</option>
            </select>
            <label for="special">Special</label>
            <textarea :placeholder="'e.g Replace some words with Emojis'" name="special" id="" cols="30" rows="3" :value="getAIPopUpFields.special" @input="this.$store.commit('client/setAIPopUpFields', {prop: 'special', val: $event.target.value})"></textarea>
            <div class="btn-wrap">
              <button class="btn-save" @click.prevent="onSubmit()"><i class="fa-solid fa-wand-magic-sparkles"></i>Generate</button>
              <button class="btn-close" @click.prevent="onClose()"><i class="fas fa-times"></i>Close</button>
            </div>
        </div>
        <div class="ai-generate-fields" v-if="showApprovalFields === true && this.showLoader === false">
          <label for="generatedContent">Generated content</label>
          <textarea name="generatedContent" id="" cols="30" rows="25" :value="getAIPopUpFields.generatedContent"></textarea>
          <div class="btn-wrap">
              <button class="btn-save" @click.prevent="onApprove()"><i class="fa-solid fa-wand-magic-sparkles"></i>Approve</button>
              <button class="btn-close" @click.prevent="onDeny()"><i class="fas fa-times"></i>Deny</button>
            </div>
        </div>
        <div class="ai-generate-fields" v-if="this.showLoader">
    
          <div class="loader-circle">
            <img v-bind:src="contility" alt="The Contility logo" title="The Contility logo" />
            <div class="loader"></div>
          </div>
          <div class="loader-text">
            <h3>Generating please wait…</h3>
          </div>

        </div>
    </div>
</template>


<script>
import { mapGetters } from 'vuex';

export default {
  data(){
    return {
      open: false,
      showApprovalFields: false,
      showLoader: false,
      contility: require("./../../assets/img/mycelium-logo.svg"),
    }
  },
  computed: {
    ...mapGetters('client', ['getAIPopUpFields', 'getCurrentNode']),
  },
  watch:{
        'getAIPopUpFields.modals.social': {
        deep: true,
          async handler() {
              this.fillInContent();
            }
        },
        'getAIPopUpFields.modals.blog': {
        deep: true,
          async handler() {
              this.fillInContent();
            }
        },
        'getAIPopUpFields.generatedContent': {
        deep: true,
          async handler(val) {
                console.log('getAIPopUpFields.generatedContent watcher', val);
                if(val){
                  this.showApprovalFields = true;
                  this.showLoader = false;
                }else{
                  this.showApprovalFields = false;
                }
            }
        },
  },
  methods: {
    async onSubmit(){
      try{
        this.showLoader = true;
        if(this.getAIPopUpFields.modals.blog){
          await this.$store.dispatch('client/AIGenerateText', 'blog');
        }
        if(this.getAIPopUpFields.modals.social){
          await this.$store.dispatch('client/AIGenerateText', 'social');
        }
        this.showLoader = false;
      }catch(error){
        console.log('onSubmit ai pop up error', error);
      }
    },
    fillInContent(){
      this.$store.commit('client/setAIPopUpFields', {prop: 'keywords', val: `${this.getCurrentNode.fieldGroups.keyword.fields.keyword.value}`});
      this.$store.commit('client/setAIPopUpFields', {prop: 'details', val: `${this.getCurrentNode.fieldGroups.details.fields.details.value}`});
      this.$store.commit('client/setAIPopUpFields', {prop: 'intent', val: `${this.getCurrentNode.fieldGroups.intent.fields.intent.value}`});
    },
    onClose(){
      this.$store.commit('client/setAIPopUpFields', {prop: 'modals/blog', val: false})
      this.$store.commit('client/setAIPopUpFields', {prop: 'modals/social', val: false})
      this.$store.commit('client/setAIPopUpFields', {prop: 'generatedContent', val: ''});
    },
    onApprove(){
      this.$store.dispatch('client/approveAIGeneratedContent', {status: true, format: this.getAIPopUpFields.modals.blog ? 'blog' : 'social'});
      this.$store.commit('client/setAIPopUpFields', {prop: 'modals/blog', val: false})
      this.$store.commit('client/setAIPopUpFields', {prop: 'generatedContent', val: ''});
      this.showApprovalFields = false;
      this.showLoader = false
    },
    onDeny(){
      this.$store.dispatch('client/approveAIGeneratedContent', {status: false, format: this.getAIPopUpFields.modals.blog ? 'blog' : 'social'});
      // this.$store.commit('client/setAIPopUpFields', {prop: 'modals/blog', val: false})
      this.$store.commit('client/setAIPopUpFields', {prop: 'generatedContent', val: ''});
      this.showApprovalFields = false;
      this.showLoader = false
    }
  },
  mounted(){
    this.fillInContent();
  }
};
</script>

<style>
@import './../../assets/scss/components/_social.scss';
</style>




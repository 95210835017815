
<template>
  <VirtualList
        ref="virtualRef"
        v-model="this.localElements"
        :dataKey="'id'"
        :keeps="10"
        :group="{ name: 'group-2', put: true, pull: true, revertDrag: true }"
        :sortable="true"
        handle=".hover-icon"
        :style="{ height: index === 1 ? '450px' : 'auto'}"
        @drop="handleEnd"
        @drag="handleStart"
    >
      <template #item="{ record }">
        <div class="item-group" @click="handleClick($event, record)" :id="record.id" :class="{ 'no-drag': getContentTreeSiblingIds.includes(record.id)}">
          <div class="item-wrap">
            <i class="hover-icon fa-solid fa-grip-dots fa-rotate-90"></i>
            <div :id="record.id" :status="statusColor(record)" :level="record.level" class="item" :style="{ border: `1px solid ${statusColor(record)}` }" :class="{isImported : this.isImported}">
              {{ this.isImported ? record.value : `${record.name} - [ ${record.value} ] - ${reachStat(record)}` }}
            </div>
          </div>
          <nested
            class="item-sub"
            :elements="record.elements"
            :index="index + 1"
          />
        </div>
    </template>
</VirtualList>
</template>

<script>
import {mapGetters} from "vuex"
// import draggable from "vuedraggable";
import VirtualList from 'vue-virtual-draglist';


let main = null
let sidebarWrapper = null
let nodeOverview = null

export default {
  name: "nested",
  data() {
    return {
      localElements: this.elements,
    }
  },
  components: {
    VirtualList
  },
  watch:{
    'this.elements': {
      handler: function (val) {
        console.log('this.elements watcher', [this.elements, val]);
      },
      deep: true
    }
  },
  mounted(){
    main = document.getElementById("main")
		sidebarWrapper = document.getElementById("sidebarWrapper")
		nodeOverview = document.getElementById("node-overview")
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
        filter: ".no-drag",
        preventOnFilter: true,
      };
    },
    ...mapGetters("views", ["getSidebarOpenState"]),
		...mapGetters("client", ["getLockContentTree", "getClientContentTree", "getChangedNode", "getContentTreeFilters", "getCurrentNode", "getClientData", "getHighlightedNode", "getListView", "getClientSlug", "getContentTreeSiblingIds"]),
  },
  methods:{
    handleStart(item) {
      const id = item.key;
      console.log('handleStart', id);
      this.$store.commit('client/setLockListCategories', true);
      document.querySelectorAll('.item-sub').forEach(block => {
        if(block.parentElement.id !== id){
          block.classList.add('height-change');
        }
      });
    },
    closeSidebar() {
			this.$store.dispatch("views/closeSidebar")
			if (!this.getSidebarOpenState) {
				sidebarWrapper.classList.remove("open")
				sidebarWrapper.classList.add("closed")
			}
		},
    openNodeOverview() {
			if (nodeOverview.classList.contains("overview-closed")) {
				main.classList.add("overview-open")
				main.classList.remove("overview-closed")
				nodeOverview.classList.add("overview-open")
				nodeOverview.classList.add("overview-closed")
			}
		},
    handleClick(event, element) {
      event.stopPropagation();
      this.$store.commit('client/setActiveNode', element.id);
      this.closeSidebar()
			this.openNodeOverview()
			this.$store.dispatch("client/selectNode", element.id);
    },
    handleEnd(item){
      const id = item.key;
      console.log(`Item with id has been moved`, id);
      this.$store.commit('client/setChangedNode', id);
      this.$store.commit('client/setLockListCategories', false);
      document.querySelectorAll('.item-sub').forEach(block => {
        block.classList.remove('height-change');
      });
    },
    reachStat(node){
      let val = '';
      node.status === 'category' ? val = node.accumulativeReach : val = node.reach
      return val;
    },
    statusColor(node){
					//update the node stroke colour using the node status
					const pink = "255,80,162"
					const blue = "42,187,238"
					const orange = "255,131,68"
					const green = "24,178,140"
					const grey = "79,81,112"
					let rgbValue = grey
					switch (node.status) {
						case "title created":
							rgbValue = pink
							break
						case "assigned":
							rgbValue = blue
							break
						case "pending review":
							rgbValue = orange
							break
						case "published":
							rgbValue = green
							break
						case "archived":
							rgbValue = grey
							break
					}
          let strokeColor = `rgba(${rgbValue},1)`;
          let nodeIsFiltered = this.getContentTreeFilters.status === node.status || this.getContentTreeFilters.author === node.author || this.getContentTreeFilters.created === node.created ? true : false;
          let filtersSelected = Object.keys(this.getContentTreeFilters).length > 0
          if (!nodeIsFiltered && filtersSelected) {
              strokeColor = `rgba(${rgbValue}, 0.1)`
          }
          return strokeColor;
    },
  },
  props: {
    elements: {
      required: true,
      type: Array
    },
    isImported:{
      type: Boolean
    },
    index:{
      type: Boolean
    }
  },
};
</script>

<style scoped>
.item-container {
  max-width: 20rem;
  margin: 0;
}
.item {
  padding: 1rem;
  background-color: #131129;
  color: white;
  cursor: pointer;
  border-radius: 7px;
  margin-bottom: 10px;
  width: 100% !important;
  overflow: hidden;
}
.item-sub {
  margin: 0 0 0 1rem;
}
.flip-list-move {
  transition: transform 0.5s !important;
}
.no-move {
  transition: transform 0s !important;
}
.ghost {
  opacity: 0.4 !important;
}
.hover-icon{
    cursor: move;
}
.height-change{
  min-height: 30px !important;
  border: 2px dotted grey;
  border-radius: 7px;
  margin: 20px 0;
}

.ghost{
  border: 5px solid red !important;
}
</style>


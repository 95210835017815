<template>
	<div class="client-list list-view">
		<div class="list-header">
			<div class="col">
				<p>Clients</p>
			</div>
			<div class="col span-2 last">
				<form id="search-items">
					<input type="text" placeholder="Search" v-model="clientNameSearchQuery" />
					<button class="submit" type="submit"><i class="fas fa-search"></i></button>
				</form>
			</div>
		</div>
		<div class="grouping" v-for="(value, letter) in sortedClients" v-bind:key="letter" v-bind:data-letter="letter">
			<div class="col">
				<div class="heading">
					<div class="letter" v-bind:id="letter">
						<p>{{ letter }}</p>
					</div>
				</div>
				<!-- end heading -->
			</div>
			<!-- end col -->
			<div class="col span-3">
				<div class="clients items">
					<template v-for="clientName in value" v-bind:key="clientName.slug">
						<article class="client item grid-container">
							<div class="col">
								<router-link :to="{path: `/client/${clientName.slug}/dashboard/`}">
									<p class="client-name item-name">{{ clientName.clientName }}</p>
								</router-link>
							</div>
							<!-- end col -->
							<div v-if="can(getAuthData, 'update_own_client_settings')">
								<div class="col last edit">
									<button class="edit" title="Edit client">
										<router-link :to="{path: `/admin/clients/edit/${clientName.slug}`}">
											<i class="fad fa-cog"></i>
										</router-link>
									</button>
								</div>
							</div>
							<!-- end col -->
						</article>
					</template>
				</div>
				<!-- end clients -->
			</div>
			<!-- end col -->
		</div>
	</div>
	<!-- end client-list -->
</template>
<script>
import axios from "axios"
import {mapGetters} from "vuex"
export default {
	data() {
		return {
			sortedClients: {}, //use to order clients by letter
			clientNameSearchQuery: "",
		}
	},
	created() {
		this.getClients()
		this.getDeletedClients()
	},
    watch:{
        clientNameSearchQuery(){
            this.searchClientsByClientName()
        }
    },
	methods: {
        searchClientsByClientName(){
            this.getClients(this.clientNameSearchQuery);
        },
		async getClients(optionalClientNameSearchQuery = undefined) {

			
            
            // Clear the array
            this.clientsArray = [];
			
            try {
				const params = new URLSearchParams()
				// params.append('JWT', this.$store.getters.getToken);
				// params.append('RFT', this.$store.getters.getRefreshToken);

				const response = await axios.get("/api/clients/findAllClients", params, {
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
				})
				// JSON responses are automatically parsed.
				this.clientsArray = response.data
				//console.log('clientsarray', this.clientsArray);
				this.sortClients(optionalClientNameSearchQuery)
			} catch (error) {
				console.log("oops an error", error)
			}
		},
		async getDeletedClients() {
            
            // Clear the array
            this.deletedClientsArray = [];
			
            try {
				const params = new URLSearchParams()
				// params.append('JWT', this.$store.getters.getToken);
				// params.append('RFT', this.$store.getters.getRefreshToken);

				const response = await axios.get("/api/clients/findDeletedClients", params, {
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
				})
				// JSON responses are automatically parsed.
				this.deletedClientsArray = response.data
			} catch (error) {
				console.log("oops an error", error)
			}
		},
		sortClients(optionalClientNameSearchQuery = undefined) {
			const currentUser = this.getAuthData;

			console.log(currentUser.role.permissions);

            // Clear the object
            this.sortedClients = {};

			//get the first letter of client name
			function findFirstLetter(string) {
				return string.charAt(0)
			}


			// Log the initial clients array
			console.log("Original clientsArray:", this.clientsArray)


			let filteredClients = [...this.clientsArray]
				if (optionalClientNameSearchQuery) {
					filteredClients = filteredClients.filter(client =>
						client.clientName.toLowerCase().includes(optionalClientNameSearchQuery.toLowerCase())
					)
					console.log("Filtered clients after search:", filteredClients)
				}

				const userHasPermission = currentUser.role.permissions.includes("read_other_client_data")||currentUser.role.permissions.includes("do_everything")
				console.log("User has 'read_other_client_data' permission:", userHasPermission)

				console.log("assigned clients: ", Object.keys(currentUser.assignedClients))
				// const assignedClientsArray = Object.keys(currentUser.assignedClients)
			const assignedClientsSlugs = Object.keys(currentUser.assignedClients)
	console.log("Assigned clients (slugs):", assignedClientsSlugs)

			// Filter clients based on the user's permissions
			const validClients = filteredClients.filter(client => {
				const isAssigned = assignedClientsSlugs.includes(client.slug)
		console.log(`Client: ${client.clientName}, Assigned: ${isAssigned}`)
		return userHasPermission || isAssigned
			})
			console.log("Valid clients after permission check:", validClients)

			// Group and sort clients by the first letter
			validClients.forEach(client => {
				const letter = findFirstLetter(client.clientName)
				if (!this.sortedClients[letter]) {
					this.sortedClients[letter] = []
				}
				this.sortedClients[letter].push(client)
			})

			// Ensure alphabetical order within each group
			Object.keys(this.sortedClients).forEach(letter => {
				this.sortedClients[letter].sort((a, b) =>
					a.clientName.toLowerCase().localeCompare(b.clientName.toLowerCase())
				)
			})

			// Log the final sorted clients
	console.log("Sorted clients:", this.sortedClients)

			//console.log(this.sortedClients)
		},
	},
	computed: {
		...mapGetters("auth", ["getAuthData", "can"]),
	},
}
</script>

<template>
	<div class="search-filters" v-if="getClientContentTree.rootNodeId">
		<div class="filters">
			<div class="filters-tree">
				<FilterSearch :key="filterSearchKey" @selected="searchSelected($event)" :placeHolder="searchPlaceHolder" :options="getClientContentTree.nodes" :labelBy="(node) => `${node.name} ${node.title}`" :modelValue="getCurrentNode" />
			<p class="title"><i class="fas fa-filter"></i> <span class="hidden-item dark">Filter</span></p>
			<div class="hidden-item fade">
				<!-- DATA -->
				<div class="filter-item date" v-if="dates">
					<label>Date</label>
					<div class="styled-select" id="date">
						<VueNextSelect :key="selectKey" id="selectedDate" @selected="setFilter($event.date, 'created')" v-bind:options="dates" label-by="date" close-on-select></VueNextSelect>
					</div>
					<!-- end styled-select -->
				</div>
				<!-- end filter-item -->

				<div class="filter-item author" v-if="getClientUsers">
					<label>Author</label>
					<div class="styled-select select" id="author">
						<VueNextSelect :key="selectKey" id="selectedAuthor" @selected="setFilter($event.fullName, 'author')" v-bind:options="authors" label-by="fullName" close-on-select></VueNextSelect>
					</div>
					<!-- end styled-select -->
				</div>
				<!-- end filter-item -->

				<!-- STATUS -->
				<div class="filter-item status" v-if="status">
					<label>Status</label>
					<div class="styled-select select legend-select" id="status">
						<VueNextSelect :key="selectKey" id="selectedStatus" @selected="setFilter($event.status, 'status')" v-bind:options="status" label-by="status" close-on-select>
							<template #label="{selected}">
								<template v-if="selected">
									<span class="color" v-bind:style="'background-color:' + selected.color"></span><span class="hidden-item title">{{ selected.status }}</span>
								</template>
								<template v-else>Show All</template>
							</template>
							<template #dropdown-item="{option}">
								<!-- <div>{{ option.flag }} {{ option.label }}</div> -->
								<span class="color" v-bind:style="'background-color:' + option.color"></span><span class="hidden-item title">{{ option.status }}</span>
							</template>
						</VueNextSelect>
					</div>
					<!-- end styled-select -->
				</div>
				<!-- end filter-item -->
				<p class="clear-filters" @click="clearContentTreeFilters">Clear filters</p>
				<p v-if="showFiltersClearedMessage">Filters Cleared!</p>
			</div>
			
			</div>

			<!-- end hidden-item -->
			<div class="legend">
				<p class="title"><span class="hidden-item dark">Legend</span></p>
				<ul>
					<li v-for="status in legend" v-bind:key="status.status">
						<span class="color" v-bind:style="'background-color:' + status.color"></span><span class="hidden-item title">{{ status.status }}</span>
					</li>
				</ul>
			</div>
			<!--<p>{{ searchQuery }}</p>
			<p>{{ localContentTreeFilters.date }}</p>
			<p>{{ localContentTreeFilters.author }}</p>
			<p>{{ localContentTreeFilters.status }}</p>-->
		</div>
		<!-- end filters -->
	</div>
	<!-- end search-filters -->
</template>

<script>
import VueNextSelect from "vue-next-select"
import FilterSearch from "../forms/inputs/FilterSearch.vue"
import "vue-next-select/dist/index.min.css"
import {mapGetters} from "vuex"

export default {
	// setup() {
	// 	const status = ref([
	// 		{
	// 			status: "Title Created",
	// 			color: "#FF50A1"
	// 		},
	// 		{
	// 			status: "Assigned",
	// 			color: "#2ABBEE"
	// 		},
	// 		{
	// 			status: "Pending review",
	// 			color: "#FF8344"
	// 		},
	// 		{
	// 			status: "Published",
	// 			color: "#18B28C"
	// 		},
	//   ])

	// 	return {
	// 		status,
	// 	}
	// },
	data() {
		return {
			showFiltersClearedMessage: false,
			searchQuery: "",
			searchPlaceHolder: "",
			dates: [{date: "Show All"}, {date: "2023"}, {date: "2024"}],
			authors: [{username: "Show All", fullName: "Show All"}],
			status: [
				{status: "Show All", color: ""},
				{status: "Title Created", color: "#FF50A1"},
				{status: "Assigned", color: "#2ABBEE"},
				{status: "Pending Review", color: "#FF8344"},
				{status: "Published", color: "#18B28C"},
			],
			result: null,
			localContentTreeFilters: {},
			selectKey: 0,
			filterSearchKey: 0,
		}
	},
	created() {
		this.setSearchPlaceHolder()
	},
	mounted() {
		this.populateAuthorsFilter()
	},
	methods: {
		setSearchPlaceHolder() {
			if (this.getCurrentNode.name && this.getCurrentNode.parent !== "root") {
				this.searchPlaceHolder = this.getCurrentNode.name
			} else {
				this.searchPlaceHolder = "Search Content"
			}
		},
		populateAuthorsFilter() {
			if (this.getClientUsers) {
				this.authors = [...this.authors, ...this.getClientUsers]
			}
		},
		async searchSelected($event) {
			// console.log("nodeSelected", $event.id)
			await this.$store.dispatch("client/selectNode", $event.id)
			this.$emit("selectNode")
			// console.log("emit select node")
			this.searchPlaceHolder = this.getCurrentNode.name
			++this.filterSearchKey
		},
		setFilter($event, filterName) {
			this.localContentTreeFilters[filterName] = $event
			this.$store.dispatch("client/updateContentTreeFilters", {...this.localContentTreeFilters})
			this.showFiltersClearedMessage = false
		},
		clearContentTreeFilters() {
			this.$store.dispatch("client/clearContentTreeFilters")
			++this.selectKey
			this.showFiltersClearedMessage = true
			setTimeout(() => {
				this.showFiltersClearedMessage = false
			}, 3000)
		},
	},
	computed: {
		legend() {
			return this.status.slice(1) //slice makes copy of status array instead of using original
		},
		...mapGetters("client", ["getClientUsers", "getClientMainContact", "getClientContentTree", "getCurrentNode"]),
		fieldValue() {
			return (fieldGroup, fieldTitle) => {
				return this.$store.getters["client/getNodeFieldValue"](fieldGroup, fieldTitle)
			}
		},
	},
	components: {
		VueNextSelect,
		FilterSearch,
	},
}
</script>
<style scoped>
@import "../../../node_modules/vue-next-select/dist/index.min.css";
</style>

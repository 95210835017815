<template>
	<div class="add-client-form">
		<p v-if="!isEditClient" class="show-form toggle-button dark" v-bind:class="{active: isActive}" data-container="toggle-1" v-on:click="toggleForm()">
			<span class="add"><i class="fas fa-plus-circle"></i></span> {{ isActive ? "Close Form" : "Add a client" }}
		</p>
		<div class="toggle-container" v-bind:class="{active: isActive || isEditClient}" v-if="isActive || isEditClient" id="toggle-1">
			<form @submit.prevent id="add-client-form" class="dark">
				<div class="form-row">
					<div class="upload-photo">
						<div class="photo" :style="logoBgImage()">
							<img v-bind:src="logoBgImage()" v-if="logoBgImage()" />
						</div>
						<label for="upload-photo">Upload profile picture</label>
						<input type="file" id="upload-photo" name="upload-photo" :class="{error: v$.$error}" v-on:change="selectFile($event)" />
					</div>
					<!-- end upload-photo -->
				</div>
				<!-- end form-row -->
				<div class="form-row">
					<input type="text" id="company" v-model="clientName" :class="{error: v$.$error}" placeholder="Client Name" />
					<IconErrors v-if="v$.$error" :errors="v$.$errors" :inputsVmodel="'clientName'" />
					<!-- end styled-select -->
				</div>

				<div class="form-row">
					<!-- <input type="text" id="slug" :class="{error: v$.$error}" placeholder="Client Slug" required v-model="clientSlug" :readonly="isEditClient"/> -->
					<input type="text" id="slug" :value="parsedSlug" :class="{error: v$.$error}" placeholder="Client Slug" @input="updateClientSlug($event.target.value)" :readonly="isEditClient" :disabled="isEditClient"/>
					<IconErrors v-if="v$.$error" :errors="v$.$errors" :inputsVmodel="'parsedSlug'" />
					<!-- end styled-select -->
				</div>

				<div class="form-row" id="assign-users">
					<label>Assign users</label>
					<div class="column-wrap">
						<div class="styled-select select" id="assign-users-select">
							<!-- This can be reimplemented if necessary, displays full name & email -->
							<!-- <VueNextSelect searchable="" @selected="selectUser($event)" v-bind:options="userSelectOptions" :label-by="(user)=>{return `${user.fullName} - ${user.email}`}" placeholder="Username" close-on-select></VueNextSelect> -->
							<VueNextSelect
								searchable=""
								@selected="selectUser($event)"
								v-bind:options="userSelectOptions"
								:label-by="
									(user) => {
										return `${user.username}`
									}
								"
								placeholder="Username"
								close-on-select
								:value="selectedUser"
							></VueNextSelect>
						</div>
						<!-- end styled-select -->
						<!-- <span class="add" v-on:click="assignUsersToClient()">Add</span> -->
					</div>
					<div v-if="selectedUsers.length > 0" class="selected-users">
						<span>Users to add:</span>
						<ul class="selected-users__list">
							<li v-for="user in selectedUsers" :key="user.id">
								<div class="profile-image">
									<img :src="user.avatarUrl" :alt="user.fullName" :title="user.fullName" v-if="user.avatarUrl" />
									<div class="user-letter" v-else>{{ user.fullName.charAt(0) }}</div>
								</div>
								<span>{{ user.fullName }}</span>
								<button 
                v-if="user.id !== currentUser.id" 
                class="remove" 
                title="Remove user" 
                v-on:click="removeSelectedUser(user.id)">
                <i class="fas fa-minus-circle"></i>
            </button>
							</li>
						</ul>
					</div>
					<!-- end column-wrap -->
				</div>
				<div class="form-row" id="assign-users">
					<label>Assign Main Contact</label>
					<div class="column-wrap">
						<div class="styled-select select" id="assign-users-select">
							<!-- This can be reimplemented if necessary, displays full name & email -->
							<!-- <VueNextSelect :placeholder="getClientMainContact ? getClientMainContact.fullName : 'Assign main contact'" :options="mainContactSelectOptions" :label-by="(user)=>{return `${user.fullName} - ${user.email}`}" close-on-select @selected="selectMainContact($event)"></VueNextSelect> -->
							<VueNextSelect
								:placeholder="getClientMainContact ? getClientMainContact.fullName : 'Assign main contact'"
								:options="mainContactSelectOptions"
								:label-by="
									(user) => {
										return `${user.username}`
									}
								"
								close-on-select
								@selected="selectMainContact($event)"
							></VueNextSelect>
						</div>
						<!-- end styled-select -->
						<!-- <span class="add" v-on:click="assignMainContact()">Assign</span> -->
						<span class="field-message" :class="{error: !assignMainContactResponse.successful}">{{ assignMainContactResponse.message }}</span>
					</div>
					<!-- end column-wrap -->
				</div>
				<button type="submit" @click.prevent="submitForm()">Save</button>
				<p class="error-message" v-if="formMessage">{{ formMessage }}</p>
				<Errors :errors="responseErrors" />
			</form>
		</div>
		<!-- end toggle-container -->
	</div>
	<!-- end add-client -->
</template>
<script>
import VueNextSelect from "vue-next-select"
import "vue-next-select/dist/index.min.css"
import Errors from "./validation/Errors.vue"
import IconErrors from "./validation/iconErrors.vue"
import useValidate from "@vuelidate/core"
import axios from "axios";
import {required} from "@vuelidate/validators"
import {mapGetters} from "vuex"

export default {
	created() {
		this.$store.dispatch("user/getAllUsers")
	},
	mounted() {
		this.setUserSelectOptions()
		this.selectedMainContact = this.getClientMainContact
		//console.log(this.userSelectOptions);
	},
	data() {
		return {
			v$: useValidate(),
			isActive: this.$route.name === "Edit Clients" || this.$route.name === "Edit Client" ? true : false,
			mainContactFieldUpdated: false,
			selectedFile: null,
			previewUrl: "",
			uploadLogo: "",
			files: [],
			selectedUser: {},
			selectedUsers: [],
			selectedMainContact: {},
			assignMainContactResponse: "",
			userSelectOptions: [{username: "Jack Loader"}, {username: "Crispy Bacon"}, {username: "Tom Phippen"}, {username: "Jon M"}, {username: "Luke Block Delete"}],
			slugPlaceholder : '',
			parsedSlug: this.clientSlug,
			watchCounter: 0,
			errorMessage: "",
			formMessage: "",
			company: "",
			slug: "",
		}
	},
	validations() {
		return {
			clientName: {
				required: required,
			},
			parsedSlug: {
				required: required,
			},
		}
	},
	methods: {
		updateClientSlug(value) {
			// Update clientSlug with the new value
			this.parsedSlug = value;
			this.clientSlug = value;
    	},
		generateSlug(value) {
			return value.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
		},
		removeSelectedUser(userId) {
			const indexOfUser = this.selectedUsers.findIndex((user) => {
				return user.id === userId
			})

			this.selectedUsers.splice(indexOfUser, 1)
		},
		logoBgImage() {
			// let bgImage = this.previewUrl ? this.previewUrl : this.getClientAvatarUrl
			// let style = {backgroundImage: `url("${bgImage}")`}
			// console.log(style)
			// return style

			let bgImage = this.previewUrl ? this.previewUrl : this.getClientAvatarUrl
			return bgImage
		},
		selectFile($event) {
			let file = $event.target.files[0]
			let allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"]

			// Check if file is of allowed types
			if (!allowedFileTypes.includes(file.type)) {
				alert("File must be a jpg, jpeg or png")
			} else if (file.size > 1048576) {
				// Check if file is below 1mb
				alert("File is larger than 1mb")
			} else {
				console.log('LUKE FILE:', [file, this.selectedFile]);
				this.selectedFile = file
				this.previewUrl = URL.createObjectURL(this.selectedFile)
			}
		},
		selectMainContact($event) {
			this.selectedMainContact = $event
			this.mainContactFieldUpdated = true
		},
		selectUser($event) {
		 // Check if the selected user is the current user or already in selectedUsers
			if ($event.id === this.getAuthData.id) {
				console.log("You cannot select the current user.");
				return; // Do not add the current user to the selected list
			}

			// If the user is not already in selectedUsers, add them
			if (!this.selectedUsers.find(user => user.id === $event.id)) {
				this.selectedUsers.push($event);  // Add the user to the list
			}

			console.log('Selected Users', this.selectedUsers);
			this.selectedUser = $event; // Update selectedUser for other purposes (if needed)

		},
		async setUserSelectOptions() {
		let allUsers = await this.$store.dispatch("user/getAllUsers");
  let clientUsers = this.getClientUsers;

  // Get the current user
  const currentUser = this.getAuthData;

	console.log(currentUser);

  // Ensure the current user is in the selected users if not already present
  if (!this.selectedUsers.find((user) => user.id === currentUser.id)) {
    this.selectedUsers.push(currentUser); // Add current user to selected users
  }

  // Filter out users already assigned to the client and skip the current user
  this.userSelectOptions = allUsers.filter(
    (user) => user.id !== currentUser.id && !clientUsers.find((clientUser) => user.id === clientUser.id)
  );

  // Add the disabled current user to the options if not already present
  const currentUserOption = {
    id: currentUser.id,
    username: currentUser.username,
    fullName: currentUser.fullName,
    disabled: true, // Mark the current user as disabled
  };

  // Check if the current user option is already in the list, if not, add it as the first option
  if (!this.userSelectOptions.some((user) => user.id === currentUser.id)) {
    this.userSelectOptions.unshift(currentUserOption);
  }

		},
		async assignUsersToClient() {
			const userIds = []
			const users = this.selectedUsers

			for (let user of users) {
				userIds.push(user.id)
			}

			const client = this.$store.getters["client/getClientData"]
			const response = await this.$store.dispatch("createClientUserRelations", {client, userIds})
			await this.$store.dispatch("client/fetchClientData", client.slug)

			// Clear the selected users
			this.selectedUsers = []

			this.$emit("newUserAssignedToClient")
			return response
		},
		async assignMainContact() {
			// set the user & client to be parsed
			const user = this.selectedMainContact
			const client = this.$store.getters["client/getClientData"]
			// Fire the API request to assign the main contact
			let assignMCResponse = await this.$store.dispatch("assignUserAsMainContact", {client, user})
			// When main contact has been assigned, refetch client data
			await this.$store.dispatch("client/fetchClientData", )
			return assignMCResponse
		},
		toggleForm() {
			this.isActive = !this.isActive
			this.$emit("formOpened")
		},
		handleFileUpload(event) {
			this.file = event.target.files[0]
		},
		async submitForm() {
			this.v$.$validate()
			console.log('pre form submit', this.getAuthData);
			if (this.v$.$errors.length > 0) {
				return (this.responseErrors = [{$message: "Form has errors, please check and try again."}])
			} else {
				this.responseErrors = [{$message: ""}];
			}

			let messages = []

			const formGoogleData = this.googleData;
			const formFacebookData = this.facebookData;
					

					// Carry out user functions, assign main contact & users to client
					const userFunctions = async () => {
						if (this.selectedUsers.length > 0) {
							let assignedUsersResponse = await this.assignUsersToClient()
							if (assignedUsersResponse?.message) messages.push(assignedUsersResponse?.message)
						}
						if (this.mainContactFieldUpdated) {
							let assignMainContactResponse = await this.assignMainContact()
							if (assignMainContactResponse?.message) messages.push(assignMainContactResponse?.message)
							this.mainContactFieldUpdated = false
						}
						return;
					}

					if (this.$route.name === "Edit Client" || this.$route.name === "Edit Clients") {
						let updatedClient = await this.$store.dispatch("client/updateClient", this.selectedFile)
						if (updatedClient?.message) messages.push(updatedClient?.message)
						await this.$store.dispatch("client/fetchClientData", this.getClientSlug)
						await userFunctions()
						this.$emit("clientUpdated", messages)
					} else {

						let createClientResponse = await this.$store.dispatch("client/createClient", this.selectedFile)
						this.formMessage = createClientResponse;
						if (createClientResponse?.message) messages.push(createClientResponse?.message)

						let promises = [];

						const params = new URLSearchParams();
						const params2 = new URLSearchParams();

						const header = {
							headers: {
							'Content-Type': 'application/x-www-form-urlencoded',
							},
						};

						params.append('clientSlug', createClientResponse.client.slug);
						params.append('data', JSON.stringify(formGoogleData));
						params2.append('clientSlug', createClientResponse.client.slug);
						params2.append('data', JSON.stringify(formFacebookData));

						promises.push(axios.post('/api/clients/googleData/updateGoogleData', params, header))
						promises.push(axios.post('/api/clients/facebookData/updateFacebookData', params2, header))

						Promise.all(promises)
						.then(() => {

							console.log('Both requests completed successfully');

						})
						.catch((err) => {

							console.error('At least one of the requests failed:', err);

						})

						await userFunctions()
						this.$store.dispatch("client/clearClientData")
						this.$emit("newClientAdded", messages)

					}

		},
	},
	watch:{
		clientName: {
			immediate: true,
         	handler(newValue) {
				if(this.watchCounter === 0) {
					this.parsedSlug = this.clientSlug;
					this.watchCounter++;
				} else if (!this.isEditClient) {
					this.parsedSlug = this.generateSlug(newValue);
					this.clientSlug = this.generateSlug(newValue);
					this.watchCounter++;
				}
			}			
		},
		parsedSlug: {
			immediate: true,
         	handler() {
				this.parsedSlug = this.generateSlug(this.parsedSlug);
			}
		},
		googleData(){
			this.googleFormData = this.$store.getters["client/getClientGoogleData"];
		}
	},
	computed: {
		googleFormData: {},
		mainContactSelectOptions() {
			return [...this.getClientUsers, ...this.selectedUsers]
		},
		isEditClient() {
			return this.$route.name === "Edit Client" || this.$route.name === "Edit Clients"
		},
		clientName: {
			get() {
				return this.$store.getters["client/getClientName"] || ""
			},
			set(value) {
				this.$store.commit("client/setClientName", value)
			},
		},
		clientSlug: {
			get() {
				return this.$store.getters["client/getClientSlug"] || ""
			},
			set(value) {
				this.$store.commit("client/setClientSlug", value)
			},
		},
        googleData: {
            get() {
				return this.$store.getters["client/getClientGoogleData"] || ""
            },
		},
        googleAccountID: {
            get() {
				return this.googleData.propertyID || ""
            },
			set(value) {
                let theValue = {'propertyID': value}
				this.$store.commit("client/setClientGoogleState", theValue)
			},
		},
        googleAdsID: {
            get() {
				return this.googleData.adsID || ""
            },
			set(value) {
                let theValue = {'adsID': value}
				this.$store.commit("client/setClientGoogleState", theValue)
			},
		},
        googleSearchURL: {
            get() {
				return this.googleData.googleURL || ""
            },
			set(value) {
                let theValue = {'googleURL': value}
				this.$store.commit("client/setClientGoogleState", theValue)
			},
		},
        agencyCampaign: { // Note: this is stored in the google data for convenience
            get() {
				return this.googleData.agencyCampaign || ""
            },
			set(value) {
                let theValue = {'agencyCampaign': value}
				this.$store.commit("client/setClientGoogleState", theValue)
			},
		},
        facebookData: {
            get() {
                return this.$store.getters["client/getClientFacebookData"] || ""
            },
        },
        facebookSlug: {
            get() {
				return this.facebookData.facebookSlug || ""
            },
			set(value) {
                let theValue = {'facebookSlug': value}
				this.$store.commit("client/setClientFacebookState", theValue)
			},
		},
		...mapGetters("client", ["getClientUsers", "getClientMainContact", "getClientAvatarUrl", "getClientSlug"]),
		...mapGetters("auth", ["getAuthRoleName", "getAuthData"]),
		authIsAdmin() {
			return this.getAuthRoleName == "operator"
		},
		currentUser() {
      return this.getAuthData; // Ensure the current user data is available here
    },
	},
	components: {
		VueNextSelect,
		Errors,
		IconErrors,
	},
}
</script>
<style scoped>
@import "../../../node_modules/vue-next-select/dist/index.min.css";
</style>

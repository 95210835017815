import axios from "axios";
export default {
	async checkAuthenticatedGoogleAccessToken(context) {
		console.log("Client Slug:", context.getters.getClientSlug);
		if (console.log(context.getters.getClientSlug == "")) {
			return null;
		}
		try {
			const response = await axios.get(
				`/api/google/refreshAccessToken?slug=${context.getters.getClientSlug}`
			);
			console.log("Reponse Client Slug:", response);
			if (typeof response.data === "string") {
				context.commit("setSocial", {
					prop: "tokens/google/accessToken",
					val: response.data,
				});
			}
		} catch (error) {
			console.log("checkAuthenticatedGoogleAccessToken catch error", error);
		}
	},
};

<template>

  <div class="page-loader" :class="{showLoader : showLoader}" >

      <div class="loader-content">
          <div class="loader-circle">
            <img v-bind:src="contility" alt="The Contility logo" title="The Contility logo" />
            <div class="loader"></div>
          </div>
          <div class="loader-text">
            <h3>Loading please wait…</h3>
          </div>
      </div>

  </div>

</template>

<script>

export default {
  data() {
    return {
      showLoader: false,
      contility: require("./../../assets/img/mycelium-logo.svg"),
    };
  },
  computed: {
    hidePageLoader() {
      return this.$store.getters["client/getHidePageLoader"] || this.$store.getters["tools/getHidePageLoader"];
    },
  },
  watch: {
    hidePageLoader() {
      this.showLoader = this.hidePageLoader;

    },
  },
  mounted() {
    this.showLoader = this.hidePageLoader;

  },
  created() {
    if (typeof this.hidePageLoader === "undefined") {
      console.error('Error: hidePageLoader is undefined');
    }
  },
};

 
</script>

<style lang="scss">

.page-loader {
    position: fixed;
    z-index: 99999 ;//1001;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #242750;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    transition: all ease 0.2s;
    &.showLoader{
      opacity: 0;
      pointer-events: none;
    }



    .loader-text{
      background: #7070703f;
      border-radius: 5px;
      width: 332px;
      height: 55px;
      display: flex;
      justify-content: center;
      align-content: center;
      h3{
        margin-bottom: 0;
        display: flex;
        align-items: center;
        text-align: center;
        font-size: 23px;
        color: #E6E6F4;
      }
    }

    .loader-circle{
      display: flex;
      justify-content: center;
      margin-bottom: 56px;
      position: relative;
      img{
        position: absolute;
        width: 75px;
        z-index: 10;
        top: 26%;
      }
    }
    .loader {
      border: 4px solid #3E4264;; /* Light grey */
      border-top: 4px solid #E6E6F4; /* Blue */
      border-radius: 50%;
      width: 215px;
      height: 215px;
      animation: spin 2s linear infinite;
      background: #3E4264;
    }

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }

  }
  

</style>
